import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';

// Components
import { ClientCard } from 'components/Cards';
import { Flex, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';

// Data
// Utils
import { theme } from 'theme';
import { Modal } from 'components/Modal';
import { ButtonProps } from 'components/Buttons/Button';
import { formatHour } from 'utils/format';
import { capitalize } from 'lodash';
import {
  Appointment,
  AppointmentStatus,
  ProcedureFull,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { useAuth } from '../../../hooks/useAuth';
import { ClientAppRoutes } from '../../../ClientApp';
import { useHistory } from 'react-router';

interface AppointmentCardProps {
  procedure: ProcedureFull;
  appointment?: Appointment;
  refetchProcedure: () => void;
  customButtons?: [ButtonProps];
}

const Wrapper = styled.div`
  padding: ${theme.spacing.space16};
  border-radius: ${theme.spacing.space16};
  border: 1px solid ${theme.colors.gray3};
`;

const AppointmentCard: FC<AppointmentCardProps> = ({
  procedure,
  appointment,
  refetchProcedure,
  customButtons,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isCustomer } = useAuth();
  const [openedCancelModal, toggleCancelModal] = useState(false);

  const { execute: cancelAppointment, state: cancelAppointmentState } =
    useApi<Appointment>(
      `/procedures/${procedure.id}/appointments/${procedure.signature_appointment_id}/cancel`,
      {
        method: 'POST',
        onSuccess: () => {
          toggleCancelModal(false);
          refetchProcedure();
        },
      },
    );

  const customerRedirectCancel = () => {
    history.push({
      pathname: ClientAppRoutes.MESSAGING,
      state: {
        defaultMessage: t('appointment.cancel_default_message'),
      },
    });
  };

  const cancelAppointmentCb = useCallback(() => {
    if (appointment) {
      cancelAppointment({
        endpoint: `/procedures/${procedure.id}/appointments/${appointment.id}/cancel`,
        onSuccess: () => {
          appointment.status = AppointmentStatus.CANCELLED;
        },
      });
    }
  }, [appointment]);

  if (!appointment || appointment.status === AppointmentStatus.CANCELLED)
    return null;

  return (
    <>
      <ClientCard
        iconName="Calendar"
        title={t('appointment.details.title')}
        buttonsDirections={{ xs: 'column' }}
        buttons={
          appointment && [
            ...(customButtons ?? []),
            {
              content: t('appointment.details.button.cancel'),
              primary: false,
              fullWidth: true,
              disabled: isCustomer
                ? new Date(appointment.end_date).getTime() <
                  new Date().getTime()
                : false,
              onClick: () =>
                isCustomer ? customerRedirectCancel() : toggleCancelModal(true),
            },
          ]
        }
      >
        <Wrapper>
          <Flex marginBottom={{ xs: 'space16' }} direction={{ xs: 'column' }}>
            <Text
              fontStyle="body1"
              weight="bold"
              content={capitalize(
                format(new Date(appointment.start_date), 'cccc dd LLLL yyyy', {
                  locale: frLocale,
                }),
              )}
            />
            <Text
              fontStyle="body2"
              weight="medium"
              content={t('appointment.range', {
                start: formatHour(appointment.start_date),
                end: formatHour(appointment.end_date),
              })}
            />
          </Flex>
          <Flex
            marginBottom={{ xs: appointment.notes ? 'space16' : 'none' }}
            alignItems="center"
          >
            <IconButton
              color={theme.colors.green1}
              backgroundColor={theme.colors.green4}
              iconName="Location"
              rounded
              size="xs"
            />
            <Text
              fontStyle="body2"
              weight="medium"
              content={appointment.address || t('appointment.details.no_place')}
              marginLeft={{ xs: 'space8' }}
            />
          </Flex>
          {appointment.notes && (
            <Flex alignItems="center">
              <IconButton
                color={theme.colors.green1}
                backgroundColor={theme.colors.green4}
                iconName="InfoCircle"
                rounded
                size="xs"
              />
              <Text
                fontStyle="body2"
                weight="medium"
                content={appointment.notes}
                marginLeft={{ xs: 'space8' }}
              />
            </Flex>
          )}
        </Wrapper>
      </ClientCard>
      <Modal opened={openedCancelModal}>
        <Flex alignItems="center" marginBottom={{ xs: 'space16' }}>
          <IconButton
            iconName="Danger"
            color={theme.colors.salmon1}
            backgroundColor={theme.colors.salmon3}
            rounded
          />
          <Text
            fontStyle="heading5"
            weight="bold"
            content={t('appointment.details.modal.cancel.title')}
            marginLeft={{ xs: 'space16' }}
          />
        </Flex>
        <Flex>
          <Flex marginRight={{ xs: 'space24' }}>
            <Button
              content={t('close')}
              onClick={() => toggleCancelModal(false)}
            />
          </Flex>
          <Button
            content={t('appointment.details.button.cancel')}
            primary
            onClick={() => cancelAppointmentCb()}
            $loading={cancelAppointmentState.loading}
          />
        </Flex>
      </Modal>
    </>
  );
};

export default AppointmentCard;
