import React, { FC } from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { InputText } from '../FormTemplate/Fields/InputText';
import { useTranslation } from 'react-i18next';
import { TextArea } from '../FormTemplate/Fields/TextArea';
import { Button, IconButton } from '../Buttons';
import { Partner, PartnerButton } from '../../types/resources';
import { InputFilePreview } from '../FormTemplate/Fields/InputFilePreview';
import { theme } from '../../theme';
import InputSelect from '../FormTemplate/Fields/InputSelect';
import { InputColorPicker } from '../FormTemplate/Fields/InputColorPicker';
import { CustomIcon } from '../../theme/styles/icons';
import { IconlyIcon } from '../../types/react-iconly';
import { useApi } from '../../hooks/useApi';
import useToaster from '../../hooks/useToaster';

type PartnerData = Omit<Partner, 'created_at' | 'updated_at'> & {
  logo_file: File | null;
  buttons?: (PartnerButton & { db_id?: string })[];
};

interface PartnerFormProps {
  loading: boolean;
  onSubmit: (data: PartnerData) => void;
  onDelete?: () => void;
  partner?: Partner;
}

const PartnerForm: FC<PartnerFormProps> = (props) => {
  const { t } = useTranslation();
  const { loading, onSubmit, partner, onDelete } = props;
  const { error: toasterError, success: toasterSuccess } = useToaster();

  const methods = useForm<PartnerData>({
    defaultValues: {
      title: partner?.title,
      subtitle: partner?.subtitle,
      description: partner?.description,
      text_to_action: partner?.text_to_action,
      card_label: partner?.card_label,
      buttons: partner?.buttons?.map((button) => ({
        ...button,
        db_id: button.id,
      })),
    },
  });

  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    name: 'buttons',
    control,
  });

  const onSubmitHandler: SubmitHandler<PartnerData> = (data) => {
    onSubmit(data);
  };

  const {
    execute: deleteButton,
    state: { loading: deleteButtonLoading },
  } = useApi(`/partners/${partner?.id}/button`, {
    method: 'DELETE',
  });

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className="tw-flex tw-flex-col tw-gap-x-16 tw-gap-y-8 tw-relative"
      >
        <div className="tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8">
          <Controller
            control={control}
            name="logo_file"
            render={({ field, fieldState: { error } }) => (
              <InputFilePreview
                type={'file'}
                accept="image/jpeg, image/png, image/svg+xml, image/webp, image/gif"
                label={t('partners.form.logo.label')}
                error={error?.message}
                required
                ref={field.ref}
                preview={partner?.logo_url}
                onChange={(file) => {
                  setValue(field.name, file ?? null);
                }}
                name={field.name}
              />
            )}
          />
          <InputText
            {...register('card_label', {
              required: t('error.form.required').toString(),
              maxLength: { value: 255, message: 'Texte trop long' },
            })}
            error={errors.card_label}
            label={t('partners.form.card_label.label')}
            placeholder={t('partners.form.card_label.placeholder')}
            width="100%"
          />
          <InputText
            {...register('title', {
              required: t('error.form.required').toString(),
              maxLength: { value: 255, message: 'Texte trop long' },
            })}
            error={errors.title}
            label={t('partners.form.title.label')}
            placeholder={t('partners.form.title.placeholder')}
            width="100%"
          />
          <InputText
            {...register('subtitle', {
              required: t('error.form.required').toString(),
              maxLength: { value: 255, message: 'Texte trop long' },
            })}
            error={errors.subtitle}
            label={t('partners.form.subtitle.label')}
            placeholder={t('partners.form.subtitle.placeholder')}
            width="100%"
          />
          <TextArea
            {...register('description', {
              required: t('error.form.required').toString(),
            })}
            error={errors.description}
            label={t('partners.form.description.label')}
            placeholder={t('partners.form.description.label')}
            width="100%"
            rows={3}
          />
          <TextArea
            {...register('text_to_action', {
              required: t('error.form.required').toString(),
            })}
            error={errors.text_to_action}
            label={t('partners.form.text_to_action.label')}
            placeholder={t('partners.form.text_to_action.label')}
            width="100%"
            rows={3}
          />
        </div>
        {partner && (
          <div className="">
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-mb-8">
              <h3 className="tw-font-bold tw-font-value tw-text-2xl">
                {t('partners.form.button.section')}
              </h3>
              <IconButton
                iconName="Plus"
                backgroundColor={theme.colors.salmon1}
                color={theme.colors.white}
                onClick={() =>
                  append({
                    link: '',
                    text: '',
                    bg_color: '#EC8569',
                    text_color: '#FFFFFF',
                    icon: '',
                    tag: '',
                  })
                }
              />
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-x-16 tw-gap-y-8">
              {fields.map((field, index) => (
                <div className="tw-flex tw-flex-col tw-gap-8" key={field.id}>
                  <div className="tw-flex tw-items-center tw-gap-4">
                    <span className="tw-font-bold tw-font-value tw-text-xl">
                      {t('partners.form.button.title')} {index + 1}
                    </span>
                    <IconButton
                      iconName="minus"
                      backgroundColor={theme.colors.red1}
                      color={theme.colors.white}
                      onClick={async () => {
                        if (field.db_id) {
                          deleteButton({
                            endpoint: `/partners/${partner?.id}/button/${field.db_id}`,
                            onSuccess: () => {
                              toasterSuccess(
                                t('partners.form.button.delete.success'),
                              );
                              remove(index);
                            },
                          });
                        } else {
                          remove(index);
                        }
                      }}
                    />
                  </div>
                  <InputText
                    {...register(`buttons.${index}.tag`, {
                      required: t('error.form.required').toString(),
                      maxLength: { value: 100, message: 'Texte trop long' },
                    })}
                    error={errors.buttons?.[index]?.tag}
                    label={t('partners.form.button.tag.label')}
                    placeholder={t('partners.form.button.tag.placeholder')}
                    width="100%"
                  />
                  <Controller
                    control={control}
                    name={`buttons.${index}.icon`}
                    render={({ field, fieldState: { error } }) => (
                      <InputSelect
                        error={error}
                        label={t('partners.form.button.icon.label')}
                        placeholder={t('partners.form.button.icon.placeholder')}
                        width="100%"
                        options={[
                          ...Object.values(CustomIcon),
                          ...Object.values(IconlyIcon),
                        ].map((icon) => ({ label: icon, value: icon }))}
                        isIcon
                        {...field}
                      />
                    )}
                  />
                  <InputText
                    {...register(`buttons.${index}.text`, {
                      required: t('error.form.required').toString(),
                      maxLength: { value: 255, message: 'Texte trop long' },
                    })}
                    error={errors.buttons?.[index]?.text}
                    label={t('partners.form.button.text.label')}
                    placeholder={t('partners.form.button.text.placeholder')}
                    width="100%"
                  />
                  <InputText
                    {...register(`buttons.${index}.link`, {
                      required: t('error.form.required').toString(),
                      maxLength: { value: 255, message: 'Texte trop long' },
                    })}
                    error={errors.buttons?.[index]?.link}
                    label={t('partners.form.button.link.label')}
                    placeholder={t('partners.form.button.link.placeholder')}
                    width="100%"
                  />
                  <InputColorPicker
                    name={`buttons.${index}.bg_color`}
                    label={t('partners.form.button.bg_color.label')}
                    error={errors.buttons?.[index]?.bg_color}
                  />
                  <InputColorPicker
                    name={`buttons.${index}.text_color`}
                    label={t('partners.form.button.text_color.label')}
                    error={errors.buttons?.[index]?.text_color}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {partner ? (
          <div className="tw-flex tw-items-start tw-gap-4">
            <Button
              content={t('partners.edit.submit')}
              type="submit"
              $loading={loading}
              primary
            />
            <Button
              content={t('partners.edit.delete')}
              onClick={onDelete}
              type="button"
              $loading={loading}
            />
          </div>
        ) : (
          <div className="tw-flex tw-items-start tw-gap-4">
            <Button
              content={t('partners.add.submit')}
              type="submit"
              $loading={loading}
              primary
            />
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default PartnerForm;
