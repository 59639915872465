import { FC } from 'react';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { useApi } from '../../../hooks/useApi';
import useToaster from '../../../hooks/useToaster';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PartnerForm from '../../../components/Partners/PartnerForm';
import { getRouteWithParams } from '../../../utils/router';
import { AdminAppRoutes } from '../../../AdminApp';
import { Partner } from '../../../types/resources';

const PartnerCreate: FC = () => {
  const { success, error } = useToaster();
  const { t } = useTranslation();
  const history = useHistory();

  const {
    execute: createMessageTemplate,
    state: { loading },
  } = useApi('/partners', { method: 'POST' });

  return (
    <div>
      <Breadcrumbs />
      <h1 className="tw-font-bold tw-font-value tw-text-3xl tw-mb-8">
        {t('partners.add.title')}
      </h1>
      <PartnerForm
        loading={loading}
        onSubmit={(data) => {
          const body = new FormData();
          body.append('title', data.title);
          body.append('subtitle', data.subtitle);
          body.append('description', data.description);
          body.append('card_label', data.card_label);
          body.append('text_to_action', data.text_to_action);

          if (data.logo_file) {
            body.append('logo', data.logo_file, data.logo_file.name);
          }

          createMessageTemplate({
            body: body,
            onSuccess: async (result) => {
              success(t('partners.add.success'));
              history.push({
                pathname: getRouteWithParams(AdminAppRoutes.PARTNER_EDIT, {
                  partnerId: (result.value as Partner).id,
                }),
              });
            },
            onError: () => {
              error(t('partners.add.error'));
            },
          });
        }}
      />
    </div>
  );
};

export default PartnerCreate;
