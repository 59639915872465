import { FC } from 'react';
import ClientPartners from '../../../pages/client/ClientPartners';
import { Header } from '../../common/Header/Header';

const PartnerPreview: FC = (props) => {
  return (
    <>
      <div className="tw-absolute tw-left-0 tw-right-0">
        <Header />
      </div>
      <ClientPartners />
    </>
  );
};

export default PartnerPreview;
