import { FC } from 'react';
import { Modal } from '../Modal';
import MessageTemplatesList from './MessageTemplatesList';
import { File as CustomFile } from '../../types/resources';

interface MessageTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (
    templateId: string | undefined,
    content: string,
    attachments?: CustomFile[],
  ) => void;
}

const MessageTemplateModal: FC<MessageTemplateModalProps> = (props) => {
  const { isOpen, onClose, onSelect } = props;

  return (
    <Modal opened={isOpen} onClose={onClose} width="1024px">
      <MessageTemplatesList isModal onSelect={onSelect} />
    </Modal>
  );
};

export default MessageTemplateModal;
