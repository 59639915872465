import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../components/Images';
import { useApi } from '../../hooks/useApi';
import { Page, Partner } from '../../types/resources';
import { IconlyIcon } from '../../types/react-iconly';
import { CustomIcon } from '../../theme/styles/icons';
import cx from 'classnames';
import { theme } from '../../theme';

const ClientPartners: FC = () => {
  const { t } = useTranslation();

  const { execute: getPage, state: getPageState } =
    useApi<Page>(`/pages/partners`);

  const { execute: getPartners, state: getPartnersState } =
    useApi<Partner[]>(`/partners/full`);

  const { execute: clickButton } = useApi(`/partners/buttons/:id/click`, {
    method: 'POST',
  });

  const partners = getPartnersState.data?.value ?? [];

  useEffect(() => {
    getPage();
    getPartners({ query: { enabled: true } });
  }, []);

  return (
    <div className="tw-w-full tw-max-w-[1360px] mx-auto tw-py-4 sm:tw-py-8 tw-px-4 sm:tw-px-8 md:tw-px-16 lg:tw-px-4">
      <h1 className="tw-font-value tw-font-bold">
        {getPageState.data?.value.title}
      </h1>
      <p className="tw-my-4 sm:tw-my-8">
        {getPageState.data?.value.description}
      </p>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-6">
        {partners.map((partner, i) => (
          <div
            key={i}
            className="tw-flex tw-flex-col tw-border tw-border-border tw-bg-white tw-rounded-2xl tw-items-center tw-shadow-sm tw-overflow-hidden"
          >
            <div className="tw-bg-[#FCFCFC] tw-py-6 tw-px-4  xl:tw-py-12 xl:tw-px-12 tw-w-full tw-flex tw-justify-center tw-items-center tw-relative">
              <img
                className="tw-w-full tw-max-h-16 tw-object-contain"
                src={partner.logo_url}
                alt={partner.title}
              />
              <span className="tw-absolute tw-top-1 tw-right-3 tw-text-[10px] tw-font-bold tw-text-white tw-bg-green1 tw-px-4 tw-py-2 tw-rounded-md">
                {partner.card_label}
              </span>
            </div>
            <div className="tw-px-7 tw-pt-8 tw-w-full">
              <p>
                <strong>{partner.title}</strong> {partner.subtitle}
              </p>
              <p className="tw-my-8 tw-text-sm">{partner.description}</p>
            </div>
            <p className="tw-font-bold tw-px-7 tw-pb-2 tw-text-left tw-w-full tw-mt-auto tw-text-sm">
              {partner.text_to_action}
            </p>
            <div className="tw-flex tw-flex-wrap tw-w-full tw-flex-row tw-justify-center tw-items-center">
              {partner.buttons?.map((button, i, buttons) => (
                <button
                  type="button"
                  key={i}
                  onClick={() => {
                    clickButton({
                      endpoint: `/partners/buttons/${button.id}/click`,
                    });
                    window.open(button.link, '_blank');
                  }}
                  className={cx(
                    'tw-flex tw-flex-row tw-gap-1 tw-p-3 tw-text-center tw-no-underline tw-text-inherit hover:tw-no-underline tw-justify-center tw-w-1/2',
                    {
                      '!tw-w-full':
                        buttons.length % 2 === 1 && i === buttons.length - 1,
                    },
                  )}
                  style={{
                    backgroundColor: button.bg_color || theme.colors.salmon1,
                    color: button.text_color || theme.colors.white,
                  }}
                >
                  <Icon
                    name={
                      button.icon as
                        | keyof typeof IconlyIcon
                        | keyof typeof CustomIcon
                    }
                    primaryColor={button.text_color || theme.colors.white}
                    filled
                  />
                  <span className="tw-font-bold tw-text-sm">{button.text}</span>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientPartners;
