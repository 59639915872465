import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { Flex, Line, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';
import { Modal } from 'components/Modal';
import { Preview } from 'components/Viewer';

import { DocumentFull, Procedure } from '../../types/resources';
import { formatDocumentTitle } from '../../utils/format';
import { sm } from '../../theme/styles/mediaQueries';

interface ClientFileModalProps {
  opened: boolean;
  onClose: () => void;
  document: DocumentFull;
  procedure: Procedure;
}

const CustomModal = styled(Modal)`
  padding: 0;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
`;

const CustomLine = styled(Line)`
  height: 100%;
`;

const PickerWrapper = styled.div`
  width: 100%;
  height: 800px;
`;

const Header = styled(Flex)``;

const RelativeWrapper = styled.div`
  position: relative;
  width: 100%;

  ${sm(`
    width: 800px;
  `)}
`;

const FixedWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors.white};
`;

const ClientFileModal: FC<ClientFileModalProps> = ({
  document,
  opened,
  onClose,
  procedure,
}) => {
  const { t } = useTranslation();

  return (
    <CustomModal opened={opened} onClose={onClose}>
      <RelativeWrapper>
        <Header
          alignItems="center"
          justify="between"
          style={{ columnGap: theme.spacing.space24 }}
          paddingTop={{ xs: 'space24' }}
          paddingRight={{ xs: 'space16' }}
          paddingBottom={{ xs: 'space24' }}
          paddingLeft={{ xs: 'space16' }}
        >
          <Flex alignItems="center">
            <IconButton
              iconName="Document"
              color={theme.colors.salmon2}
              backgroundColor={theme.colors.salmon3}
              rounded
            />
            <Text
              fontStyle="heading5"
              content={formatDocumentTitle(document)}
              marginLeft={{ xs: 'space16' }}
            />
          </Flex>

          <Flex style={{ columnGap: theme.spacing.space16 }}></Flex>
        </Header>
        <Line />
        <Wrapper>
          <Flex
            direction={{ xs: 'column' }}
            justify="between"
            alignItems="center"
          >
            {document.file && (
              <PickerWrapper>
                <Preview procedure={procedure} document={document} />
              </PickerWrapper>
            )}
          </Flex>
          <CustomLine />
        </Wrapper>
        <FixedWrapper>
          <Line />
          <Flex
            direction={{ xs: 'column', md: 'row' }}
            justify="between"
            paddingRight={{ xs: 'space40' }}
            paddingTop={{ xs: 'space16' }}
            paddingLeft={{ xs: 'space40' }}
            paddingBottom={{ xs: 'space16' }}
          >
            <Button
              content={t('upload.back')}
              marginRight={{ xs: 'none', md: 'space16' }}
              marginBottom={{ xs: 'space16', md: 'none' }}
              onClick={onClose}
              iconLeft={{ name: 'ArrowLeftSquare' }}
            />
          </Flex>
        </FixedWrapper>
      </RelativeWrapper>
    </CustomModal>
  );
};

export default ClientFileModal;
