import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import styled from 'styled-components';

import { ConventionPreview } from 'container/common';
import { UploadConventionCard } from 'container/admin';

import { theme } from 'theme';

import { Col, Flex, Requires, Row, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';
import { Modal, ModalHeader, UploadModal } from 'components/Modal';
import { TipCard } from 'components/Cards';
import config from 'config/app';
import { Permission } from 'components/User';
import {
  DivorceAgreementFull,
  ProcedureFull,
  ProcedureStepFull,
  ProcedureStepStatus,
  ProcedureStepType,
  Role,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { useAuth } from '../../../hooks/useAuth';

type WarningModalProps = {
  opened: boolean;
  onClose: () => void;
  onConfirm: (reset: boolean) => void;
};

const WarningModal: FC<WarningModalProps> = ({
  opened,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal opened={opened} onClose={onClose}>
      <ModalHeader
        onClose={onClose}
        iconName="Unlock"
        content={t('convention.warning.title')}
      />

      <Text fontStyle="body1" marginTop={{ xs: 'space16' }}>
        <Trans i18nKey="convention.warning.description" />
      </Text>

      <Flex marginTop={{ xs: 'space40' }} justify={'between'}>
        <Button
          content={t('convention.warning.reset')}
          marginRight={{ xs: 'space16' }}
          onClick={() => onConfirm(true)}
        />
        <Button
          content={t('convention.warning.no_reset')}
          marginRight={{ xs: 'space16' }}
          onClick={() => onConfirm(false)}
        />
        <Button
          content={t('convention.warning.cancel')}
          onClick={onClose}
          primary
        />
      </Flex>
    </Modal>
  );
};

interface ConventionViewProps {
  procedure: ProcedureFull;
  refetchProcedure: () => void;
}

const CustomButton = styled(Button)`
  padding: ${theme.spacing.space16};
`;

const ConventionView: FC<ConventionViewProps> = ({
  procedure,
  refetchProcedure,
}) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [modalState, setModalState] = useState<boolean>(false);
  const [warningModalState, setWarningModalState] = useState<boolean>(false);
  const [displayDates, setDisplayDates] = useState<boolean>(false);
  const [shouldResetStep, setShouldResetStep] = useState<boolean>(true);

  const onTryUploadAgreement = () => {
    if (isValidate) {
      setWarningModalState(true);
    } else {
      setModalState(true);
    }
  };

  const onCloseWarningModal = () => {
    setWarningModalState(false);
    setShouldResetStep(true);
  };

  const onConfirmWarningModal = (reset: boolean) => {
    setWarningModalState(false);
    setModalState(true);
    setShouldResetStep(reset);
  };

  const onClose = () => {
    setModalState(false);
    setShouldResetStep(true);
  };

  const { execute: getConventionStep, state: getConventionStepState } =
    useApi<ProcedureStepFull>(
      `/procedures/${procedure.id}/steps/${ProcedureStepType.REVIEW_AGREEMENT}`,
    );
  const conventionStep = getConventionStepState.data?.value;

  const { execute: getDivorceAgreement, state: getDivorceAgreementState } =
    useApi<DivorceAgreementFull>(
      `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}`,
    );
  const divorceAgreement = getDivorceAgreementState.data?.value;

  const {
    execute: uploadAgreement,
    state: { loading: uploadAgreementLoading },
  } = useApi(
    `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/upload`,
    {
      method: 'POST',
      onSuccess: () => {
        getDivorceAgreement();
        refetchProcedure();
        onClose();
      },
    },
  );

  const {
    execute: replaceAgreement,
    state: { loading: replaceAgreementLoading },
  } = useApi(
    `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/replace`,
    {
      method: 'POST',
      onSuccess: () => {
        getDivorceAgreement();
        refetchProcedure();
        onClose();
      },
    },
  );

  const {
    execute: validateAgreement,
    state: { loading: validateAgreementLoading },
  } = useApi(
    `procedures/${procedure.id}/divorce-agreements/${divorceAgreement?.id}/validate`,
    {
      method: 'POST',
    },
  );

  const {
    execute: lockAgreement,
    state: { loading: lockAgreementLoading },
  } = useApi(
    `procedures/${procedure.id}/divorce-agreements/${divorceAgreement?.id}/lock`,
    {
      method: 'POST',
      onSuccess: () => {
        getDivorceAgreement();
      },
    },
  );

  const validateAgreementCb = useCallback(() => {
    if (divorceAgreement?.id) {
      validateAgreement({
        endpoint: `procedures/${procedure.id}/divorce-agreements/${divorceAgreement?.id}/validate`,
        onSuccess: () => {
          refetchProcedure();
          getConventionStep();
        },
      });
    }
  }, [divorceAgreement]);

  useEffect(() => {
    if (procedure.divorce_agreement_id) {
      getDivorceAgreement();
    }
  }, [procedure]);

  useEffect(() => {
    if (divorceAgreement?.project_document_id) {
      getConventionStep();
    }
  }, [divorceAgreement]);

  const onConfirmUpload = (blob: Blob) => {
    const body = new FormData();
    body.append('file', blob, 'convention.pdf');

    if (shouldResetStep) {
      uploadAgreement({ body });
    } else {
      replaceAgreement({ body });
    }
  };

  if (!divorceAgreement) return null;

  const adminOnly = [Role.ADMIN, Role.MANAGER, Role.LAWYER];

  if (!divorceAgreement.project_document_id) {
    return (
      <Permission roles={adminOnly}>
        <Row marginBottom={{ xs: 'space16' }}>
          <Col xs={12} xl={8}>
            <UploadConventionCard
              onConfirmUpload={onConfirmUpload}
              loading={uploadAgreementLoading || replaceAgreementLoading}
            />
          </Col>
        </Row>
      </Permission>
    );
  }

  const inReview = conventionStep?.status === ProcedureStepStatus.IN_REVIEW;
  const inProgress = conventionStep?.status === ProcedureStepStatus.IN_PROGRESS;
  const isValidate = conventionStep?.status === ProcedureStepStatus.VALIDATED;
  const isLocked = divorceAgreement.locked;
  // Cannot reupload if step "Archivage" is at least "IN_REVIEW"
  const cannotReuploadAgreement = procedure.steps.some(
    (s) =>
      s.step === ProcedureStepType.ARCHIVE_SIGN &&
      [ProcedureStepStatus.IN_REVIEW, ProcedureStepStatus.VALIDATED].includes(
        s.status,
      ),
  );

  const onDisplayDates = () => {
    setDisplayDates(!displayDates);
  };

  const onValidate = () => {
    validateAgreementCb();
  };

  const onWaiting = () => {
    lockAgreement();
  };
  const showDateButton = false; // ?

  const getButtonText = () => {
    if (isLocked) {
      return t('convention.waiting');
    } else if (inProgress || inReview) {
      return t('convention.to_waiting');
    } else if (isValidate) {
      return t('convention.validate');
    }

    return '';
  };

  return (
    <>
      <Row marginBottom={{ xs: 'space16' }}>
        <Col xs={12} md={8} marginBottom={{ xs: 'none', md: 'space32' }}>
          <div
            style={{
              pointerEvents: modalState ? 'none' : 'initial',
              width: '100%',
            }}
          >
            <ConventionPreview
              procedure={procedure}
              agreement={divorceAgreement}
            />
          </div>
        </Col>
        <Permission roles={adminOnly}>
          <Col xs={12} md={4} vertical>
            <Flex marginBottom={{ xs: 'space32' }}>
              <CustomButton
                onClick={onTryUploadAgreement}
                content={t('convention.upload_new_one')}
                backgroundColor={theme.colors.salmon3}
                primary
                disabled={cannotReuploadAgreement}
              />
            </Flex>
            <Flex marginBottom={{ xs: 'space24' }}>
              <TipCard type="info" title="">
                <Flex
                  marginLeft={{ xs: 'space16' }}
                  direction={{ xs: 'column' }}
                >
                  <Text
                    fontStyle="body1"
                    content={t('convention.verify')}
                    marginBottom={{ xs: 'space8' }}
                  />
                  <Flex>
                    {divorceAgreement.project_document_id && (
                      <Text
                        fontStyle="body2"
                        content={t('convention.last_version_sent', {
                          date: format(
                            new Date(divorceAgreement.updated_at),
                            config.dateFormat,
                          ),
                        })}
                        color={theme.colors.gray6}
                        weight="bold"
                      />
                    )}
                    <Requires value={showDateButton}>
                      <IconButton
                        iconName="ArrowUp"
                        backgroundColor={theme.colors.gray3}
                        color={theme.colors.black}
                        hoverBackgroundColor={theme.colors.gray2}
                        rounded
                        stroke="regular"
                        onClick={onDisplayDates}
                        style={{
                          transform: displayDates
                            ? 'rotate(0.5turn)'
                            : 'rotate(0)',
                        }}
                      />
                    </Requires>
                  </Flex>
                  <Requires value={displayDates}>
                    <Flex
                      marginTop={{ xs: 'space16' }}
                      direction={{ xs: 'column' }}
                    >
                      <Text
                        fontStyle="body2"
                        color={theme.colors.gray6}
                        content={t('convention.last_versions')}
                      />
                      {/*<DateList>*/}
                      {/*  {data.agreement.uploadedAt.map((day) => (*/}
                      {/*    <DateItem key={`date_${day}`}>*/}
                      {/*      <DateText*/}
                      {/*        fontStyle="body2"*/}
                      {/*        color={theme.colors.gray6}*/}
                      {/*        content={format(*/}
                      {/*          new Date(day),*/}
                      {/*          config.dateFormat,*/}
                      {/*        )}*/}
                      {/*      />*/}
                      {/*    </DateItem>*/}
                      {/*  ))}*/}
                      {/*</DateList>*/}
                    </Flex>
                  </Requires>
                </Flex>
              </TipCard>
            </Flex>
            {divorceAgreement.project_document_id && (
              <Button
                onClick={onWaiting}
                content={getButtonText()}
                fullWidth
                $loading={lockAgreementLoading}
                disabled={isValidate || isLocked}
              />
            )}
            {inReview && (
              <Button
                onClick={onValidate}
                content={t('convention.validation.modal.validate')}
                fullWidth
                primary={true}
                marginTop={{ xs: 'space16' }}
                $loading={validateAgreementLoading}
                disabled={user?.id !== procedure.manager_id}
              />
            )}
          </Col>
        </Permission>
      </Row>

      <Permission roles={adminOnly}>
        <UploadModal
          opened={modalState}
          onClose={onClose}
          onConfirm={onConfirmUpload}
          loading={uploadAgreementLoading || replaceAgreementLoading}
          tip={false}
          documentName="Convention de divorce"
        />
      </Permission>

      <WarningModal
        opened={warningModalState}
        onClose={onCloseWarningModal}
        onConfirm={onConfirmWarningModal}
      />
    </>
  );
};

export default ConventionView;
