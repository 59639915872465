export enum IconlyIcon {
  'Search' = 'Search',
  'Folder' = 'Folder',
  'Wallet' = 'Wallet',
  'Graph' = 'Graph',
  'Bookmark' = 'Bookmark',
  'Category' = 'Category',
  'Home' = 'Home',
  'Notification' = 'Notification',
  'Chat' = 'Chat',
  'Heart' = 'Heart',
  'Heart2' = 'Heart2',
  'Paper' = 'Paper',
  'PaperPlus' = 'PaperPlus',
  'PaperNegative' = 'PaperNegative',
  'PaperFail' = 'PaperFail',
  'PaperDownload' = 'PaperDownload',
  'PaperUpload' = 'PaperUpload',
  'Send' = 'Send',
  'Password' = 'Password',
  'Swap' = 'Swap',
  'Work' = 'Work',
  'Scan' = 'Scan',
  'Activity' = 'Activity',
  'Calendar' = 'Calendar',
  'Message' = 'Message',
  'Video' = 'Video',
  'Plus' = 'Plus',
  'Chart' = 'Chart',
  'Game' = 'Game',
  'Bag' = 'Bag',
  'Bag2' = 'Bag2',
  'TicketStar' = 'TicketStar',
  'MoreCircle' = 'MoreCircle',
  'MoreSquare' = 'MoreSquare',
  'Discount' = 'Discount',
  'Buy' = 'Buy',
  'InfoCircle' = 'InfoCircle',
  'InfoSquare' = 'InfoSquare',
  'Danger' = 'Danger',
  'CloseSquare' = 'CloseSquare',
  'TickSquare' = 'TickSquare',
  'Discovery' = 'Discovery',
  'Location' = 'Location',
  'Document' = 'Document',
  'Setting' = 'Setting',
  'TimeSquare' = 'TimeSquare',
  'TimeCircle' = 'TimeCircle',
  'VolumeUp' = 'VolumeUp',
  'VolumeDown' = 'VolumeDown',
  'VolumeOff' = 'VolumeOff',
  'Star' = 'Star',
  'Ticket' = 'Ticket',
  'Camera' = 'Camera',
  'User' = 'User',
  'AddUser' = 'AddUser',
  'TwoUsers' = 'TwoUsers',
  'People' = 'People',
  'Login' = 'Login',
  'Logout' = 'Logout',
  'Download' = 'Download',
  'Upload' = 'Upload',
  'Voice' = 'Voice',
  'Voice2' = 'Voice2',
  'Delete' = 'Delete',
  'Edit' = 'Edit',
  'EditSquare' = 'EditSquare',
  'Play' = 'Play',
  'ShieldDone' = 'ShieldDone',
  'ShieldFail' = 'ShieldFail',
  'Show' = 'Show',
  'Hide' = 'Hide',
  'Filter' = 'Filter',
  'Filter2' = 'Filter2',
  'Image' = 'Image',
  'Image2' = 'Image2',
  'Call' = 'Call',
  'Calling' = 'Calling',
  'CallMissed' = 'CallMissed',
  'CallSilent' = 'CallSilent',
  'Lock' = 'Lock',
  'Unlock' = 'Unlock',
  'ChevronDownCircle' = 'ChevronDownCircle',
  'ChevronRightCircle' = 'ChevronRightCircle',
  'ChevronUpCircle' = 'ChevronUpCircle',
  'ChevronLeftCircle' = 'ChevronLeftCircle',
  'ChevronDown' = 'ChevronDown',
  'ChevronRight' = 'ChevronRight',
  'ChevronUp' = 'ChevronUp',
  'ChevronLeft' = 'ChevronLeft',
  'ArrowDownSquare' = 'ArrowDownSquare',
  'ArrowRightSquare' = 'ArrowRightSquare',
  'ArrowUpSquare' = 'ArrowUpSquare',
  'ArrowLeftSquare' = 'ArrowLeftSquare',
  'ArrowDown' = 'ArrowDown',
  'ArrowRight' = 'ArrowRight',
  'ArrowUp' = 'ArrowUp',
  'ArrowLeft' = 'ArrowLeft',
  'CaretRight' = 'CaretRight',
  'CaretDown' = 'CaretDown',
  'CaretUp' = 'CaretUp',
  'CaretLeft' = 'CaretLeft',
}
