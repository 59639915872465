import styled, { CSSProperties, keyframes, css } from 'styled-components';

import { CustomIcon } from 'theme/styles/icons';
import { FC } from 'react';
import { Iconly } from 'react-iconly';
import { IconlyIcon } from 'types/react-iconly';
import { theme } from 'theme';

const pulseXs = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
`;
const pulseMd = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
`;

const SVG = styled.svg<SpanProps>`
  color: ${(props) => props.color};
  cursor: ${(props) => props.onClick && 'pointer'};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
`;

const IconlyWrapper = styled.span<{
  $size: number;
  $notification?: 'xs' | 'md';
}>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;

  svg {
    vertical-align: baseline;
  }

  ${({ $notification }) =>
    $notification &&
    css`
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: ${$notification === 'xs' ? '0' : '-3'}px;
        right: ${$notification === 'xs' ? '0' : '-1'}px;
        width: ${$notification === 'xs' ? '6' : '10'}px;
        height: ${$notification === 'xs' ? '6' : '10'}px;
        border-radius: 50%;
        border: 1px white white;
        background-color: ${theme.colors.white};
        animation: ${$notification === 'xs' ? pulseXs : pulseMd} 2s infinite;
      }
    `}
`;

type Color = 'currentColor' | string;
type Size =
  | 'xs'
  | 'small'
  | 'medium'
  | 'large'
  | 'extra-large'
  | 'extra-extra-large'
  | 'extra-extra-extra-large';

interface SpanProps {
  color?: Color;
  size?: string;
}

export interface IconProps {
  name: keyof typeof IconlyIcon | keyof typeof CustomIcon;
  label?: string;
  filled?: boolean;
  primaryColor?: Color;
  secondaryColor?: Color;
  size?: Size;
  set?: 'light' | 'bold' | 'two-tone' | 'bulk' | 'broken';
  stroke?: 'light' | 'regular' | 'bold';
  fill?: 'none' | 'light' | 'regular' | 'bold';
  style?: CSSProperties;
  notification?: 'xs' | 'md';
}

export const Icon: FC<IconProps> = ({ notification, ...props }) => {
  const getSize = (size: Size | undefined): string => {
    switch (size) {
      case 'small':
        return theme.spacing.space16;
      case 'extra-extra-large':
        return theme.spacing.space40;
      case 'extra-extra-extra-large':
        return theme.spacing.space32;
      default:
        return theme.spacing.space24;
    }
  };
  const getIconlySize = (size: Size | undefined): number => {
    switch (size) {
      case 'small':
        return 16;
      case 'extra-extra-large':
        return 40;
      case 'extra-extra-extra-large':
        return 32;
      default:
        return 24;
    }
  };
  if (props.name in IconlyIcon) {
    const size = getIconlySize(props.size);

    return notification ? (
      <IconlyWrapper $size={size} $notification={notification}>
        <Iconly {...props} primaryColor={props.primaryColor} size={size} />
      </IconlyWrapper>
    ) : (
      <Iconly {...props} primaryColor={props.primaryColor} size={size} />
    );
  }

  if (props.name in CustomIcon) {
    return (
      <SVG
        aria-hidden="true"
        color={props.primaryColor}
        {...props}
        size={getSize(props.size)}
        viewBox="0 0 24 24"
      >
        {theme.icons[props.name as CustomIcon]}
      </SVG>
    );
  }

  return null;
};
