import React, { FC, useState } from 'react';
import { ChromePicker } from 'react-color';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { Flex, Text } from '../../Layout';
import RequiredField from '../../Forms/Utils/RequiredField';
import { Label } from '../../Text/Label';
import { determineIfIsFieldErrorOrString } from '../../../utils/errors';
import { theme } from '../../../theme';

interface InputColorPicker {
  label?: string;
  required?: boolean;
  error?: FieldError | string;
  name: string;
}

export const InputColorPicker: FC<InputColorPicker> = ({
  label,
  required,
  error,
  name,
}) => {
  const { control, watch } = useFormContext();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const color = watch(name);

  const handleClick = () => setDisplayColorPicker(!displayColorPicker);
  const handleClose = () => setDisplayColorPicker(false);

  return (
    <div>
      {label && (
        <Flex marginBottom={{ xs: 'space8' }}>
          <RequiredField required={required}>
            <Label dangerouslySetInnerHTML={{ __html: label }} />
          </RequiredField>
        </Flex>
      )}
      <div
        className="tw-inline-block tw-p-2 tw-border tw-border-border tw-bg-white tw-cursor-pointer tw-rounded-md"
        onClick={handleClick}
      >
        <div
          className="tw-w-28 tw-h-8"
          style={{
            background: color ? color : '#ffffff',
          }}
        />
        {error && (
          <Text
            content={
              error && determineIfIsFieldErrorOrString(error)
                ? error.message
                : error
            }
            fontStyle="body2"
            color={theme.colors.red1}
            marginTop={{ xs: 'space8' }}
          />
        )}
      </div>

      {displayColorPicker && (
        <div className="tw-absolute tw-z-10">
          <div className="tw-fixed tw-inset-0" onClick={handleClose} />
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <ChromePicker
                color={value}
                onChange={(color) => onChange(color.hex)}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
