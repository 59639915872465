import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

// Data
// Component
import { InputText } from 'components/FormTemplate/Fields/InputText';
import InputSelect from 'components/FormTemplate/Fields/InputSelect';
import { Button } from 'components/Buttons';

// Utils
import { theme } from 'theme';
import { AdminAppRoutes } from 'AdminApp';
import { regexes } from 'utils/regex';

import { Flex, Text } from 'components/Layout';
import { InputTel } from 'components/FormTemplate/Fields/InputTel';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Department,
  HtmlTemplate,
  InternalUser,
  Role,
  UserGender,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import useToaster from '../../../hooks/useToaster';
import { InputFile } from '../../../components/FormTemplate/Fields/InputFile';

const FullWidth = styled.div`
  grid-column: 1/-1;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space8};
`;

type UpdateUserInput = Omit<InternalUser, 'gender'> & {
  gender: string | undefined;
  fees_file: File | null;
  letter_template_id: string | null;
  yo_process_value: string | null;
  support_days: string | null;
  support_hours: string | null;
  support_booking_url: string | null;
  signature_email: string;
};

type UserUpdateFormProps = {
  user: InternalUser;
  departments: Department[];
};

const UserUpdateForm: FC<UserUpdateFormProps> = ({ user, departments }) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setValue,
  } = useForm<UpdateUserInput>({
    defaultValues: { ...user, gender: user.gender ?? undefined },
  });

  const getResourceType = (role: Role) => {
    switch (role) {
      case Role.LAWYER:
        return `lawyers`;
      case Role.MANAGER:
        return `managers`;
      default:
        return `users`;
    }
  };

  const resource = getResourceType(user.role);
  const updateUrl = `/${resource}/${user.id}`;
  const { t } = useTranslation();

  const genderOptions = Object.values(UserGender).map((value) => ({
    value: value,
    label: t(`gender.${value}`),
  }));

  const roleOptions = [
    {
      value: Role.ADMIN,
      label: t(`role.admin`),
    },
    {
      value: Role.LAWYER,
      label: t(`role.lawyer`),
    },
    {
      value: Role.MANAGER,
      label: t(`role.manager`),
    },
    {
      value: Role.SALES,
      label: t(`role.sales`),
    },
  ];

  const history = useHistory();
  const { success, error } = useToaster();
  const departmentOptions = departments.map((d) => ({
    value: d.code,
    label: d.full_name,
  }));

  const [htmlTemplatesOptions, setHtmlTemplatesOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const { execute: getHtmlTemplates, state: getHtmlTemplatesState } =
    useApi<HtmlTemplate[]>(`/html-templates`);

  const {
    execute: updateUserRequest,
    state: { loading },
  } = useApi(`/${resource}/${user.id}`, { method: 'PATCH' });

  const goBack = () => {
    history.push(AdminAppRoutes.USERS);
  };

  const onSubmit = useCallback(
    (data: UpdateUserInput) => {
      let body;

      if (resource === 'lawyers') {
        body = new FormData();
        body.set('first_name', data.first_name);
        body.set('last_name', data.last_name);
        body.set('email', data.email);
        body.set('department_code', data.department_code);
        body.set('role', data.role);
        body.set('phone', data.phone);
        body.set('signature_email', data.signature_email);
        data.gender && body.set('gender', data.gender);
        data.letter_template_id &&
          body.set('letter_template_id', data.letter_template_id);
        data.yo_process_value &&
          body.set('yo_process_value', data.yo_process_value);

        if (
          data.role === Role.LAWYER &&
          data.fees_file &&
          data.fees_file instanceof File
        ) {
          body.set('fees_file', data.fees_file);
        }
      } else {
        body = {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          department_code: data.department_code,
          phone: data.phone,
          gender: data.gender,
          support_days:
            data.role === Role.MANAGER ? data.support_days || null : undefined,
          support_hours:
            data.role === Role.MANAGER ? data.support_hours || null : undefined,
          support_booking_url:
            data.role === Role.MANAGER
              ? data.support_booking_url || null
              : undefined,
        };
      }

      updateUserRequest({
        endpoint: updateUrl,
        body,
        onSuccess: () => {
          success(t('user.update_success'));
          goBack();
        },
        onError: () => {
          error(t('user.update_error'));
        },
      });
    },
    [updateUrl],
  );

  useEffect(() => {
    if (user.role === Role.LAWYER) {
      getHtmlTemplates({
        onSuccess: (response) => {
          setHtmlTemplatesOptions(
            response.value.map((t) => ({ label: t.name, value: t.id })),
          );
        },
      });
    }
  }, [user]);

  return (
    <>
      <Flex marginTop={{ xs: 'space48' }}>
        <Text content={t('prospect.information')} fontStyle="heading3" />
      </Flex>

      <Form id="userForm" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="role"
          rules={{ required: t('error.form.user_role').toString() }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('user.user_role')}
              placeholder={t('user.user_role')}
              options={roleOptions}
              error={error}
              {...field}
              disabled
            />
          )}
        />
        {user.role === Role.LAWYER ? (
          <InputText
            {...register('signature_email', {
              required: t('error.form.required').toString(),
              pattern: {
                value: regexes.email,
                message: t('error.form.email'),
              },
            })}
            error={errors.signature_email}
            label={t('user.signature_email')}
            placeholder={t('user.email')}
            width="100%"
          />
        ) : (
          <Flex />
        )}

        <InputText
          {...register('email', {
            required: t('error.form.required').toString(),
            pattern: {
              value: regexes.email,
              message: t('error.form.email'),
            },
          })}
          error={errors.email}
          label={t('user.email')}
          placeholder={t('user.email')}
          width="100%"
        />

        <Controller
          control={control}
          name="phone"
          rules={{
            required: t('error.form.required').toString(),
            validate: (value: string) => {
              if (value && !isValidPhoneNumber(value)) {
                return t('error.form.phone').toString();
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputTel
              label={t('user.phone')}
              placeholder={t('user.phone')}
              error={error}
              {...field}
              onChange={(value) => {
                setValue(`phone`, value);
                clearErrors('phone');
              }}
            />
          )}
        />

        <InputText
          {...register('first_name', {
            required: t('error.form.required').toString(),
          })}
          error={errors.first_name}
          label={t('user.first_name')}
          placeholder={t('user.first_name')}
          width="100%"
          uppercase
        />
        <InputText
          {...register('last_name', {
            required: t('error.form.required').toString(),
          })}
          label={t('user.last_name')}
          error={errors.last_name}
          placeholder={t('user.last_name')}
          width="100%"
          uppercase
        />

        <Controller
          control={control}
          name="gender"
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('user.gender')}
              placeholder={t('user.gender')}
              options={genderOptions}
              error={error}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="department_code"
          rules={{ required: t('error.form.region').toString() }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('user.department')}
              placeholder={t('user.department')}
              options={departmentOptions}
              error={error}
              {...field}
            />
          )}
        />

        {user.role === Role.LAWYER && (
          <>
            <Controller
              control={control}
              name="fees_file"
              rules={{ required: t('error.form.template_id').toString() }}
              render={({ field, fieldState: { error } }) => (
                <InputFile
                  type={'file'}
                  size={1}
                  accept={'application/pdf'}
                  label={t('user.template_id')}
                  error={error?.message}
                  required
                  ref={field.ref}
                  value={
                    (user as any).fees_file ? [(user as any).fees_file] : []
                  }
                  onChange={(files) => {
                    setValue('fees_file', files[0] ?? null);
                  }}
                  name={field.name}
                />
              )}
            />

            <Controller
              control={control}
              name="letter_template_id"
              render={({ field, fieldState: { error } }) => (
                <InputSelect
                  label={t('user.letter_template')}
                  placeholder={t('user.letter_template')}
                  options={htmlTemplatesOptions}
                  error={error}
                  {...field}
                  disabled={getHtmlTemplatesState.loading}
                />
              )}
            />

            <InputText
              {...register('yo_process_value', {
                required: t('error.form.required').toString(),
              })}
              label={t('user.yo_process_value')}
              error={errors.yo_process_value}
              placeholder={t('user.yo_process_value')}
              width="100%"
              required
            />
          </>
        )}

        {user.role === Role.MANAGER && (
          <InputText
            {...register('support_days', {
              required: t('error.form.required').toString(),
            })}
            error={errors.support_days}
            label={t('user.support_days')}
            placeholder={t('user.support_days_placeholder')}
            width="100%"
            required
          />
        )}

        {user.role === Role.MANAGER && (
          <InputText
            {...register('support_hours', {
              required: t('error.form.required').toString(),
            })}
            error={errors.support_hours}
            label={t('user.support_hours')}
            placeholder={t('user.support_hours_placeholder')}
            width="100%"
            required
          />
        )}

        {user.role === Role.MANAGER && (
          <InputText
            {...register('support_booking_url')}
            error={errors.support_booking_url}
            label={t('user.support_booking_url')}
            placeholder={t('user.support_booking_url_placeholder')}
            width="100%"
          />
        )}

        <FullWidth>
          <Flex>
            <Button
              content={t('user.update')}
              type="submit"
              form="userForm"
              $loading={loading}
              primary
            />
          </Flex>
        </FullWidth>
      </Form>
    </>
  );
};

export default UserUpdateForm;
