import { FC } from 'react';
import { Button } from '../Buttons';
import { getRouteWithParams } from '../../utils/router';
import { AdminAppRoutes } from '../../AdminApp';
import { Partner } from '../../types/resources';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useHistory } from 'react-router-dom';
import Toggle from '../FormTemplate/Fields/Toggle';
import { useApi } from '../../hooks/useApi';
import useToaster from '../../hooks/useToaster';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { GripVertical } from 'lucide-react';

interface PartnerSortableProps {
  partner: Partner;
  refetch: () => void;
}

const PartnerSortable: FC<PartnerSortableProps> = ({ partner, refetch }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { error: toasterError, success: toasterSuccess } = useToaster();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: partner.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const {
    execute: editPartner,
    state: { loading },
  } = useApi(`/partners/${partner.id}/status`, {
    method: 'PATCH',
  });

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="tw-flex tw-items-center tw-gap-4"
    >
      <div className="tw-px-5 tw-py-5 tw-bg-white tw-items-center tw-border tw-border-border tw-rounded-2xl tw-flex tw-flex-row tw-w-full tw-gap-2">
        <GripVertical {...listeners} {...attributes} size={24} />
        <span className="tw-font-bold">{partner.title}</span>
        <div className="tw-ml-auto tw-flex tw-flex-row tw-items-center ">
          <div className="tw-flex tw-flex-row tw-divide-x">
            {partner.buttons?.map((button, index, array) => (
              <div
                key={button.id}
                className={cx('tw-flex tw-flex-col tw-px-4', {
                  '!tw-border-r tw-mr-4': index === array.length - 1,
                })}
              >
                <span className="tw-uppercase tw-text-xs tw-text-primary tw-font-bold">
                  {button.tag}
                </span>
                <span className="tw-font-bold">{button.click || 0}</span>
                <span className="tw-text-xs">clics</span>
              </div>
            ))}
          </div>
          <Toggle
            checked={partner.enabled}
            onChange={(value) =>
              editPartner({
                body: { enabled: value },
                onSuccess: () => {
                  toasterSuccess(t('partners.status.success'));
                  refetch();
                },
              })
            }
          />
        </div>
      </div>
      <div className="tw-flex tw-gap-4 tw-items-center">
        <Button
          iconLeft={{ name: 'Edit' }}
          onClick={() =>
            history.push(
              getRouteWithParams(AdminAppRoutes.PARTNER_EDIT, {
                partnerId: partner.id,
              }),
            )
          }
          primary
        />
      </div>
    </div>
  );
};

export default PartnerSortable;
