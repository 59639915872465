import { FC, Fragment, useEffect } from 'react';
import PartnerForm from '../../../components/Partners/PartnerForm';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { useApi } from '../../../hooks/useApi';
import useToaster from '../../../hooks/useToaster';
import { AdminAppRoutes } from '../../../AdminApp';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { get } from 'lodash';
import { Partner } from '../../../types/resources';
import Loader from 'react-loader-spinner';
import { theme } from '../../../theme';
import { ConfirmOptions, useConfirm } from '../../../hooks/useConfirm';

const PartnerEdit: FC = (props) => {
  const { success, error } = useToaster();
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const partnerId = get(match, 'params.partnerId', '');

  const options: ConfirmOptions = {
    title: t('partners.delete.modal.title'),
    description: t('partners.delete.modal.description'),
    confirmLabel: t('partners.delete.modal.confirm'),
    cancelLabel: t('partners.delete.modal.cancel'),
  };

  const { confirm, ConfirmationDialog, isLoading } = useConfirm(async (data) =>
    deletePartner({
      onSuccess: () => {
        success(t('partners.delete.success'));
        history.push(AdminAppRoutes.PARTNERS);
      },
      onError: () => {
        error(t('partners.delete.error'));
      },
    }),
  );

  const { execute: getPartner, state: getPartnerState } = useApi<Partner>(
    `/partners/${partnerId}`,
    {
      method: 'GET',
    },
  );

  const { execute: deletePartner } = useApi(`/partners/${partnerId}`, {
    method: 'DELETE',
  });

  const {
    execute: editPartner,
    state: { loading },
  } = useApi(`/partners/${partnerId}`, {
    method: 'PATCH',
  });

  const {
    execute: editPartnerButtons,
    state: { loading: loadingEditButtons },
  } = useApi(`/partners/${partnerId}/button`, {
    method: 'PATCH',
  });

  useEffect(() => {
    getPartner();
  }, []);

  return (
    <>
      <div>
        <Breadcrumbs />
        <h1 className="tw-font-bold tw-font-value tw-text-3xl tw-mb-8">
          {t('partners.edit.submit')}
        </h1>
        {getPartnerState.data?.value ? (
          <PartnerForm
            partner={getPartnerState?.data?.value}
            loading={loading}
            onSubmit={(data) => {
              const body = new FormData();
              body.append('title', data.title);
              body.append('subtitle', data.subtitle);
              body.append('description', data.description);
              body.append('card_label', data.card_label);
              body.append('text_to_action', data.text_to_action);

              if (data.logo_file) {
                body.append('logo', data.logo_file, data.logo_file.name);
              }

              editPartner({
                body: body,
                onSuccess: () => {
                  success(t('partners.edit.success'));
                },
                onError: () => {
                  error(t('partners.edit.error'));
                },
              });

              if (data.buttons && data.buttons.length > 0) {
                data.buttons.forEach((button) => {
                  editPartnerButtons({
                    body: {
                      icon: button.icon,
                      text: button.text,
                      link: button.link,
                      bg_color: button.bg_color,
                      text_color: button.text_color,
                      id: button.db_id,
                      tag: button.tag,
                    },
                    onSuccess: () => {
                      success(t('partners.edit.success'));
                    },
                    onError: () => {
                      error(t('partners.edit.error'));
                    },
                  });
                });
              }
              history.push({
                pathname: AdminAppRoutes.PARTNERS,
              });
            }}
            onDelete={async () => await confirm(options)}
          />
        ) : (
          <Loader
            type="TailSpin"
            height={24}
            width={24}
            color={theme.colors.salmon1}
          />
        )}
      </div>
      <ConfirmationDialog />
    </>
  );
};

export default PartnerEdit;
