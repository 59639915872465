import React, { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Components
import { theme } from 'theme';
import { Text, Flex, Requires } from 'components/Layout';
import {
  Button,
  InlineButton,
  OpportunityStatusButton,
} from 'components/Buttons';
import { ClientCard, StickerCard } from 'components/Cards';
import { Icon } from 'components/Images/Icon';

import { ClientAppRoutes } from 'ClientApp';
import { getRouteWithParams } from 'utils/router';
import { PublicAppRoutes } from 'PublicApp';
import { useAuth } from '../../../../hooks/useAuth';
import {
  CustomerEstimate,
  EstimateFull,
  ProcedureFull,
  ProcedureSpouse,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../../types/resources';
import { useApi } from '../../../../hooks/useApi';
import { useCustomer } from '../../../../hooks/useCustomer';
import NotificationButton from '../NotificationButton/NotificationButton';
import { getHideByStatus, getTypeByStatus } from '../../../../utils/status';

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

const StyledButton = styled.button`
  border: none;
  margin-top: ${theme.spacing.space16};
`;

interface MobileClientHomeProps {
  procedure?: ProcedureFull;
  estimateId?: string | null;
}

const Card = styled(ClientCard)`
  height: auto;
`;

const MobileClientHome: FC<MobileClientHomeProps> = ({
  procedure,
  estimateId,
}) => {
  const { t } = useTranslation();
  const { customer } = useCustomer();
  const { logout } = useAuth();
  const history = useHistory();

  const { execute: getEstimate, state: getEstimateState } =
    useApi<EstimateFull>(`/estimates/${procedure?.estimate_id}`);
  const estimate = getEstimateState.data?.value;

  const { execute: getEstimateCustomers, state: getEstimateCustomersState } =
    useApi<CustomerEstimate[]>(
      `/estimates/${procedure?.estimate_id}/customers`,
    );
  const estimateCustomers = getEstimateCustomersState.data?.value ?? [];

  const { execute: getProcedureSpouse, state: getProcedureSpouseState } =
    useApi<ProcedureSpouse>(
      `/procedures/${customer?.procedure_id}/spouses/${customer?.id}`,
    );
  const spouse = getProcedureSpouseState.data?.value;

  const customerPayment = estimateCustomers.find(
    (ec) => ec.customer_id === customer?.id && ec.amount_paid,
  );
  const customerHasPaid = customerPayment && customerPayment.amount_paid > 0;
  const hasToPay =
    estimate !== undefined &&
    Boolean(estimate.split_payments) &&
    !customerHasPaid;

  const goToForms = () => {
    history.push(ClientAppRoutes.FORMS);
  };

  const goToEstimateSummary = useCallback(() => {
    if (procedure?.estimate_id) {
      history.push(
        getRouteWithParams(PublicAppRoutes.QUOTE_RESUME, {
          id: procedure.estimate_id,
        }),
      );
    }
  }, [procedure]);

  const goToLawyerFees = () => {
    history.push(ClientAppRoutes.LAWYER_FEES);
  };

  const goToDocuments = () => {
    history.push(ClientAppRoutes.DOCUMENTS);
  };

  const buttonWhitelist = [
    ProcedureStepStatus.IN_PROGRESS,
    ProcedureStepStatus.IN_REVIEW,
  ];
  const isFormStep =
    procedure?.current_step.step === ProcedureStepType.FORM &&
    buttonWhitelist.includes(procedure?.current_step.status);

  const isDocumentStep =
    procedure?.current_step.step === ProcedureStepType.DOCUMENT &&
    buttonWhitelist.includes(procedure?.current_step.status);

  const showSignLawyerFeesButton =
    spouse?.lawyer_id && !spouse.signed_lawyer_fees_id;

  const getStepTranslation = (
    opportunityId?: string,
    dealId?: string | null,
    step?: ProcedureStepType,
  ) => {
    if (opportunityId && step) {
      return t(`opportunity.steps_client.${step}`);
    }

    if (dealId) {
      return t('opportunity.steps_lead.existing_deal');
    }

    return t('opportunity.steps_lead.no_deal');
  };

  useEffect(() => {
    if (procedure?.estimate_id) {
      getEstimate();
      getEstimateCustomers();
    }
  }, [procedure]);

  useEffect(() => {
    if (customer?.procedure_id) {
      getProcedureSpouse();
    }
  }, [customer]);

  const getOnClickStep = (step: ProcedureStepType) => {
    switch (step) {
      case ProcedureStepType.FORM:
        return () => history.push(ClientAppRoutes.FORMS);
      case ProcedureStepType.DOCUMENT:
        return () => history.push(ClientAppRoutes.DOCUMENTS);
      case ProcedureStepType.REVIEW_AGREEMENT:
        return () => history.push(ClientAppRoutes.CONVENTION);
      case ProcedureStepType.AGREEMENT_SENT:
        return () => history.push(ClientAppRoutes.REGISTERED_LETTERS);
      case ProcedureStepType.SIGNATURE:
        return () => history.push(ClientAppRoutes.APPOINTMENT);
      case ProcedureStepType.ARCHIVE_SIGN:
        return () => history.push(ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS);
      case ProcedureStepType.NOTARY:
        return () => history.push(ClientAppRoutes.FORMALITY_NOTARY);
      case ProcedureStepType.MARITAL_STATUS:
        return () => history.push(ClientAppRoutes.FORMALITY_MARITAL_STATUS);
      case ProcedureStepType.FINAL_SENDING:
        return () => history.push(ClientAppRoutes.FORMALITY_FINAL_SENDING);
      default:
        return () => {};
    }
  };

  const getCurrentStep = () => {
    const currentStep = procedure?.steps.sort((a, b) => b.order - a.order)[0];

    console.log(currentStep);

    if (!currentStep) {
      return null;
    }

    const customerCaseStep = procedure?.steps.find(
      (s) => s.step === currentStep.step,
    );

    const status = customerCaseStep?.status;
    let finalStatus = status;

    let displayedStatus = status
      ? t(`opportunity.status.${status}`)
      : t(`opportunity.status.UNKNOWN`);

    return {
      title: t(`opportunity.steps_client.${currentStep.step}`),
      active:
        status !== undefined &&
        [
          ProcedureStepStatus.IN_PROGRESS,
          ProcedureStepStatus.IN_REVIEW,
          ProcedureStepStatus.VALIDATED,
          ProcedureStepStatus.LOCKED,
        ].includes(status),
      hideButton:
        status !== undefined && getHideByStatus(currentStep.step, status),
      isDone: status === ProcedureStepStatus.VALIDATED,
      status: finalStatus,
      displayedStatus,
      updated_at: customerCaseStep?.updated_at,
      onClick: getOnClickStep(currentStep.step),
    };
  };

  const currentStep = getCurrentStep();

  return (
    <>
      <Flex marginBottom={{ xs: 'space16' }}>
        <Card
          iconName="Folder"
          title={t('opportunity.folder_status', {
            reference: procedure?.reference,
          })}
        >
          {procedure && (
            <>
              <div className="tw-mb-4">
                <NotificationButton procedure={procedure} />
              </div>

              {currentStep && (
                <div
                  style={{
                    backgroundColor: theme.colors.beige,
                    borderColor: theme.colors.gray3,
                  }}
                  className="tw-flex tw-flex-col tw-rounded-xl tw-p-4 tw-gap-4 tw-border"
                >
                  <Text
                    content={currentStep.title}
                    fontStyle="heading5"
                    weight="bold"
                  />

                  <OpportunityStatusButton
                    type={getTypeByStatus(currentStep.status)}
                    text={currentStep.displayedStatus}
                  />

                  <InlineButton
                    iconName="ArrowRight"
                    iconColor={theme.colors.green1}
                    backgroundColor={theme.colors.green2}
                    text={t('opportunity.consult')}
                    onClick={currentStep.onClick}
                    hoverBackgroundColor={theme.colors.salmon2}
                    hoverIconColor={theme.colors.white}
                  />
                </div>
              )}
            </>
          )}

          {estimateId && (
            <StyledButton
              onClick={() => {
                history.push({
                  pathname: PublicAppRoutes.QUOTE,
                });
              }}
            >
              <Text
                fontStyle="body3"
                content={t('quote.redo_quote')}
                weight="bold"
                whiteSpace="nowrap"
                color={theme.colors.orange1}
                transform="uppercase"
              />
            </StyledButton>
          )}
        </Card>
      </Flex>
      <Requires value={!isFormStep && !isDocumentStep}>
        {!procedure ? (
          <Flex marginBottom={{ xs: 'space8' }}>
            <Button
              content={t(
                estimateId
                  ? 'opportunity.access_recap_quote'
                  : 'opportunity.create_quote',
              )}
              primary
              fullWidth
              onClick={() => {
                if (estimateId) {
                  history.push(
                    getRouteWithParams(PublicAppRoutes.QUOTE_RESUME, {
                      id: estimateId,
                    }),
                  );
                } else {
                  history.push({
                    pathname: PublicAppRoutes.QUOTE,
                  });
                }
              }}
            />
          </Flex>
        ) : (
          <Flex marginBottom={{ xs: 'space16' }}>
            <StickerCard padding={theme.spacing.space24}>
              <Flex>
                <IconContainer>
                  <Icon name="Notification" />
                </IconContainer>
                <Text
                  fontStyle="body1"
                  content={t('mobile_info_message')}
                  marginLeft={{ xs: 'space16' }}
                />
              </Flex>
            </StickerCard>
          </Flex>
        )}
      </Requires>
      <Requires value={hasToPay}>
        <Flex marginBottom={{ xs: 'space8' }}>
          <Button
            content={t('estimate.pay')}
            primary
            fullWidth
            onClick={goToEstimateSummary}
          />
        </Flex>
      </Requires>

      <Requires value={showSignLawyerFeesButton && !hasToPay}>
        <Flex marginBottom={{ xs: 'space8' }}>
          <Button
            primary
            onClick={goToLawyerFees}
            iconLeft={{ name: 'EditSquare' }}
            fullWidth
            content={t('lawyer_agreement.sign_full')}
          />
        </Flex>
      </Requires>

      <Requires value={isFormStep}>
        <Flex marginBottom={{ xs: 'space8' }}>
          <Button
            content={t('opportunity.access_forms')}
            primary
            fullWidth
            onClick={goToForms}
          />
        </Flex>
      </Requires>
      <Requires value={isDocumentStep}>
        <Flex marginBottom={{ xs: 'space8' }}>
          <Button
            content={t('opportunity.access_documents')}
            primary
            fullWidth
            onClick={goToDocuments}
          />
        </Flex>
      </Requires>
      <Flex>
        <Button
          content={t('sign_out')}
          iconLeft={{ name: 'Logout' }}
          fullWidth
          onClick={() => {
            logout();
          }}
        />
      </Flex>
    </>
  );
};
export default MobileClientHome;
