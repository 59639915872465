import { FC, useState, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { downloadFileNew, generateFileName } from 'utils/downloadFile';

import { DocumentCommentType } from 'types/resources';

import { theme } from 'theme';
import { Flex, Line, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';
import { ModalTip, Modal } from 'components/Modal';
import { Preview } from 'components/Viewer';
import { CommentInput } from 'components/Forms';

import { DocumentFull, Procedure } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import FileDrop from '../FileDrop/FileDrop';
import { mergeFilesToPdf } from '../../utils/pdf';
import useToaster from '../../hooks/useToaster';
import { formatDocumentTitle } from '../../utils/format';

interface FileModalProps {
  opened: boolean;
  onClose: () => void;
  refetchDocuments: () => void;
  document: DocumentFull;
  editable?: boolean;
  changeable?: boolean; // can re-upload the document file
  hideTip?: boolean;
  procedure: Procedure;
}

const CustomModal = styled(Modal)`
  padding: 0;
`;

const Wrapper = styled.div<{ hideTip?: boolean }>`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const CustomLine = styled(Line)`
  height: 100%;
  flex: 1;
`;

const PickerWrapper = styled.div<{ hideTip?: boolean }>`
  width: ${(props) => (props.hideTip ? '100%' : '780px')};
  height: calc(90vh - 200px);
`;

const GhostElement = styled.div``;

const Header = styled(Flex)``;

const CommentTypeButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  margin-bottom: 25px;
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const FixedWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors.white};
`;

const FileModal: FC<FileModalProps> = ({
  document,
  opened,
  onClose,
  refetchDocuments,
  editable,
  changeable,
  hideTip,
  procedure,
}) => {
  const { success } = useToaster();
  const { t } = useTranslation();
  const [commentType, setCommentType] = useState<DocumentCommentType>();
  const [comment, setComment] = useState<string>('');
  // Display re-upload document file
  const [changing, setChanging] = useState<boolean>(false);

  const onModalClose = () => {
    onClose();
    setCommentType(undefined);
    setComment('');
  };

  const { execute: validateDocument } = useApi(
    `/procedures/${document.procedure_id}/documents/${document.id}/validate`,
    {
      method: 'POST',
      onSuccess: () => {
        refetchDocuments();
        onModalClose();
      },
    },
  );

  const { execute: commentDocument } = useApi(
    `/procedures/${document.procedure_id}/documents/${document.id}/comment`,
    {
      method: 'POST',
      onSuccess: () => {
        refetchDocuments();
        onModalClose();
      },
    },
  );

  useEffect(() => {
    const firstComment = document.documents_comments[0];
    if (firstComment) {
      firstComment.type && setCommentType(firstComment.type);
      setComment(firstComment.comment.content);
    }
  }, [document]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const onCommentTypeChange = (type: DocumentCommentType) => {
    if (commentType === type) {
      setCommentType(undefined);
    } else {
      setCommentType(type);
    }
  };

  const { execute: uploadDocument, state: uploadDocumentState } = useApi(
    `/procedures/${procedure.id}/documents/${document.id}/upload`,
    {
      method: 'POST',
      onSuccess: () => {
        success('Le document a été modifié avec succès');
        onModalClose();
      },
    },
  );
  const uploadLoading = uploadDocumentState.loading;

  const onDropFiles = (files: File[]) => {
    if (files.length) {
      mergeFilesToPdf(files).then((value) => {
        const filename = generateFileName(
          formatDocumentTitle(document),
          procedure.reference.toString(),
        );
        const file = new File([value], filename, {
          type: 'application/pdf',
        });
        const body = new FormData();
        body.append('file', file, filename);

        uploadDocument({ body });
      });
    }
  };

  const { execute: downloadDocument } = useApi<{ url: string }>(
    `/procedures/${procedure.id}/documents/${document.id}/download`,
  );

  const handleDownloadClick = () => {
    if (document.file) {
      downloadDocument({
        endpoint: `/procedures/${procedure.id}/documents/${document.id}/download`,
        onSuccess: async (result) => {
          try {
            await downloadFileNew(
              result.value.url,
              formatDocumentTitle(document),
              procedure.reference.toString(),
            );
          } catch (e) {
            console.error(e);
          }
        },
      });
    }
  };

  const valid = !commentType && !comment;

  const onSubmit = async () => {
    if (valid) {
      validateDocument();
    } else {
      commentDocument({
        body: { type: commentType, content: comment },
      });
    }
  };

  return (
    <CustomModal opened={opened} onClose={onModalClose}>
      <RelativeWrapper>
        <Header
          alignItems="center"
          justify="between"
          style={{ columnGap: theme.spacing.space24 }}
          paddingTop={{ xs: 'space24' }}
          paddingRight={{ xs: 'space16' }}
          paddingBottom={{ xs: 'space24' }}
          paddingLeft={{ xs: 'space16' }}
        >
          <Flex alignItems="center">
            <IconButton
              iconName="Document"
              color={theme.colors.salmon2}
              backgroundColor={theme.colors.salmon3}
              rounded
            />
            <Text
              fontStyle="heading5"
              content={formatDocumentTitle(document)}
              marginLeft={{ xs: 'space16' }}
            />
          </Flex>

          <Flex style={{ columnGap: theme.spacing.space16 }}>
            <Button
              content={t('upload.download')}
              iconLeft={{ name: 'Download' }}
              onClick={handleDownloadClick}
              primary
            />
            {changeable && changing && (
              <Button
                content={t('upload.view')}
                onClick={() => setChanging(false)}
              />
            )}
            {changeable && !changing && (
              <Button
                content={t('upload.modify')}
                onClick={() => setChanging(true)}
              />
            )}
          </Flex>
        </Header>
        <Line />
        <Wrapper hideTip={hideTip}>
          <Flex
            direction={{ xs: 'column' }}
            justify="between"
            alignItems="center"
          >
            {document.file && (
              <PickerWrapper hideTip={hideTip}>
                {changing ? (
                  <FileDrop
                    onDrop={onDropFiles}
                    multiple={true}
                    maxFiles={5}
                    disabled={uploadLoading}
                    hidePreview
                    compressImages
                  />
                ) : (
                  <Preview procedure={procedure} document={document} />
                )}
              </PickerWrapper>
            )}
            <GhostElement />
          </Flex>
          <CustomLine />
          {!hideTip && (
            <ModalTip tipOnly>
              {editable && (
                <>
                  <Flex
                    marginTop={{ xs: 'space16' }}
                    marginBottom={{ xs: 'space16' }}
                    direction={{ xs: 'column' }}
                  >
                    {Object.keys(DocumentCommentType).map((value) => (
                      <CommentTypeButton
                        key={value}
                        onClick={() =>
                          onCommentTypeChange(value as DocumentCommentType)
                        }
                      >
                        <IconButton
                          iconName="close"
                          color={
                            value === commentType
                              ? theme.colors.white
                              : theme.colors.black
                          }
                          backgroundColor={
                            value === commentType
                              ? theme.colors.red1
                              : theme.colors.gray2
                          }
                          rounded
                        />
                        <Text
                          fontStyle="body1"
                          weight="medium"
                          content={t(`enums:${value}`)}
                          marginLeft={{ xs: 'space16' }}
                        />
                      </CommentTypeButton>
                    ))}
                  </Flex>
                  <Flex direction={{ xs: 'column' }}>
                    <Text
                      fontStyle="body1"
                      weight="bold"
                      content={t('documents.general_comment')}
                      marginBottom={{ xs: 'space16' }}
                    />
                    <CommentInput
                      onChange={onChange}
                      placeholder={t('documents.comment_example')}
                      value={comment}
                    />
                  </Flex>
                </>
              )}
            </ModalTip>
          )}
        </Wrapper>
        <FixedWrapper>
          <Line />
          <Flex
            direction={{ xs: 'column', md: 'row' }}
            justify="between"
            paddingRight={{ xs: 'space40' }}
            paddingTop={{ xs: 'space16' }}
            paddingLeft={{ xs: 'space40' }}
            paddingBottom={{ xs: 'space16' }}
          >
            <Button
              content={t('upload.back')}
              marginRight={{ xs: 'none', md: 'space16' }}
              marginBottom={{ xs: 'space16', md: 'none' }}
              onClick={onModalClose}
              iconLeft={{ name: 'ArrowLeftSquare' }}
            />
            {editable && (
              <Button
                content={
                  valid
                    ? t('documents.validate_document')
                    : t('documents.validate_comment')
                }
                primary
                disabled={!valid && (!commentType || !comment)}
                onClick={onSubmit}
              />
            )}
          </Flex>
        </FixedWrapper>
      </RelativeWrapper>
    </CustomModal>
  );
};

export default FileModal;
