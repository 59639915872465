import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { Button } from './Buttons';
import cx from 'classnames';

interface Action {
  id: string;
  label: string;
  disabled?: boolean;
}

interface DropdownMenuProps {
  onValidate: (selectedAction: Action['id']) => void;
  actions: Action[];
  loading?: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  onValidate,
  actions,
  loading,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<Action | null>(null);

  const handleSelect = (action: Action): void => {
    setSelectedAction(action);
    setIsOpen(false);
  };

  const handleValidate = () => {
    if (selectedAction && !selectedAction.disabled) {
      onValidate(selectedAction.id);
    }
  };

  return (
    <div className={`tw-flex tw-items-center tw-gap-4 tw-p-4`}>
      <div className="tw-relative">
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="tw-flex tw-items-center tw-text-sm tw-justify-between tw-w-72 tw-px-4 tw-py-2 tw-bg-white tw-border tw-border-gray-200 tw-rounded-xl tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-gray-200"
          aria-expanded={isOpen}
          aria-haspopup="listbox"
        >
          <span
            className={cx('tw-font-bold tw-text-gray-400', {
              '!tw-text-black': selectedAction,
            })}
          >
            {selectedAction?.label || "Sélectionnez l'action à effectuer"}
          </span>
          <ChevronDown
            className={`tw-w-4 tw-h-4 tw-text-gray-400 tw-transition-transform tw-duration-200 ${
              isOpen ? 'tw-transform tw-rotate-180' : ''
            }`}
          />
        </button>

        {isOpen && (
          <div
            className="tw-absolute tw-flex tw-flex-col tw-divide-y tw-z-10 tw-mt-1 tw-bg-white tw-px-2 tw-border tw-border-gray-200 tw-rounded-xl tw-w-full tw-overflow-hidden"
            role="listbox"
          >
            {actions.map((action) => (
              <div className="tw-py-2" key={action.id}>
                <button
                  disabled={action.disabled}
                  onClick={() => handleSelect(action)}
                  className={cx(
                    'tw-w-full tw-text-left tw-px-4 tw-py-3 tw-text-sm tw-font-bold tw-bg-primary/20 hover:tw-text-white hover:tw-bg-primary tw-transition tw-focus:bg-gray-50 tw-focus:outline-none tw-rounded-xl',
                    {
                      'tw-pointer-events-none tw-opacity-50': action.disabled,
                    },
                  )}
                  role="option"
                  aria-selected={selectedAction?.id === action.id}
                >
                  {action.label}
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <Button
        type="button"
        onClick={handleValidate}
        size={'small'}
        primary
        $loading={loading}
        disabled={!selectedAction}
      >
        Valider
      </Button>
    </div>
  );
};

export default DropdownMenu;
