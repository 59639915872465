import { FC } from 'react';
import MessageTemplateForm from '../../../components/MessageTemplates/MessageTemplateForm';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { useApi } from '../../../hooks/useApi';
import useToaster from '../../../hooks/useToaster';
import { AdminAppRoutes } from '../../../AdminApp';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

const MessageTemplateCreate: FC = () => {
  const { success, error } = useToaster();
  const { t } = useTranslation();
  const history = useHistory();

  const {
    execute: createMessageTemplate,
    state: { loading },
  } = useApi('/conversations/models', { method: 'POST' });

  return (
    <div>
      <Breadcrumbs />
      <h1 className="tw-font-bold tw-font-value tw-text-3xl tw-mb-8">
        {t('message_template.add.title')}
      </h1>
      <MessageTemplateForm
        loading={loading}
        onSubmit={(data) => {
          const body = new FormData();
          body.append('title', data.title);
          body.append('content', data.content);

          if (data.attachments) {
            for (const attachment of data.attachments) {
              body.append('attachments', attachment, attachment.name);
            }
          }

          createMessageTemplate({
            body: body,
            onSuccess: () => {
              success(t('message_template.add.success'));
              history.push(AdminAppRoutes.MESSAGE_TEMPLATES);
            },
            onError: () => {
              error(t('message_template.add.error'));
            },
          });
        }}
      />
    </div>
  );
};

export default MessageTemplateCreate;
