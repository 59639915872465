import React, { FC, InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import { InputCheckboxProps } from './InputCheckbox';
import cx from 'classnames';

export interface ToggleProps
  extends Omit<InputHTMLAttributes<InputCheckboxProps>, 'onChange'> {
  label?: string;
  error?: FieldError | string;
  onChange?: (value: boolean) => void;
  className?: string;
}

const Toggle: FC<ToggleProps> = (props) => {
  const {
    label,
    error,
    onChange,
    defaultChecked,
    disabled,
    className,
    checked,
    ...rest
  } = props;

  const handleToggle = (event: any) => {
    if (disabled) return;

    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <label
      className={cx(
        'tw-flex tw-items-center tw-cursor-pointer tw-gap-3',
        {
          disabled: 'tw-opacity-50 tw-cursor-not-allowed',
        },
        className,
      )}
    >
      <div className="tw-relative">
        <input
          type="checkbox"
          className="tw-hidden"
          checked={checked}
          onChange={handleToggle}
          disabled={disabled}
        />
        <div
          className={`
          tw-w-14 tw-h-7 tw-rounded-full tw-transition-colors tw-duration-300
          ${checked ? 'tw-bg-primary' : 'tw-bg-gray-200'}
          ${disabled ? 'tw-bg-gray-300' : ''}
        `}
        >
          <div
            className={`
            tw-absolute tw-w-5 tw-h-5 tw-bg-white tw-rounded-full tw-shadow-md tw-transition-transform tw-duration-300 tw-top-1
            ${checked ? 'tw-translate-x-8' : 'tw-translate-x-1'}
          `}
          />
        </div>
      </div>
      {label && (
        <span className="tw-text-sm tw-font-medium tw-text-gray-900">
          {label}
        </span>
      )}
    </label>
  );
};

export default Toggle;
