import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';

// Component
import { Flex, Text } from 'components/Layout';
import { ConfirmForm } from 'components/ConfirmPopin/ConfirmPopin';
import { Modal } from 'components/Modal';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import InputSelect from 'components/FormTemplate/Fields/InputSelect';
import { Button } from 'components/Buttons';
import { InputTel } from 'components/FormTemplate/Fields/InputTel';

//data
import { Breadcrumbs } from 'container/admin';

// Utils
import { AdminAppRoutes } from 'AdminApp';
import { theme } from 'theme';
import { regexes } from 'utils/regex';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useApi } from '../../hooks/useApi';
import useToaster from '../../hooks/useToaster';
import {
  Department,
  InternalUser,
  Role,
  UserGender,
} from '../../types/resources';
import { InputFile } from '../../components/FormTemplate/Fields/InputFile';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const FullWidth = styled.div`
  grid-column: 1/-1;
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space48};
  margin-bottom: ${theme.spacing.space32};
`;

type CreateUserInput = Omit<InternalUser, 'gender'> & {
  gender: string | undefined;
  fees_file: File | null;
  yo_process_value: string | null;
  support_days: string | null;
  support_hours: string | null;
  support_booking_url: string | null;
  signature_email: string;
};

const UserAdd: FC = () => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    setValue,
  } = useForm<CreateUserInput>();
  const { t } = useTranslation();
  const { success, error } = useToaster();
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [createUrl, setCreateUrl] = useState<string>('users');
  const [result, setResult] = useState<CreateUserInput>();
  const [role, setRole] = useState<Role | null>(null);

  const genderOptions = Object.values(UserGender).map((value) => ({
    value: value,
    label: t(`gender.${value}`),
  }));

  const roleOptions = [
    {
      value: Role.ADMIN,
      label: t(`role.admin`),
    },
    {
      value: Role.LAWYER,
      label: t(`role.lawyer`),
    },
    {
      value: Role.MANAGER,
      label: t(`role.manager`),
    },
    {
      value: Role.SALES,
      label: t(`role.sales`),
    },
  ];

  const onSubmit = (data: CreateUserInput) => {
    setResult(data);
    setModalIsOpen(true);
  };

  const onClose = () => {
    setModalIsOpen(false);
  };

  const goBack = () => history.push(AdminAppRoutes.USERS);

  const {
    execute: createUserRequest,
    state: { loading },
  } = useApi('/users', { method: 'POST' });

  const { execute: getDepartments, state: getDepartmentsState } = useApi<
    Department[]
  >('/departments', { cache: true });
  const departments = getDepartmentsState.data?.value ?? [];
  const departmentOptions = departments.map((d) => ({
    value: d.code,
    label: d.full_name,
  }));

  useEffect(() => {
    getDepartments();
  }, []);

  const createUser = useCallback(() => {
    if (result) {
      let body;

      if (result.role === Role.LAWYER) {
        body = new FormData();
        body.set('first_name', result.first_name);
        body.set('last_name', result.last_name);
        body.set('email', result.email);
        body.set('department_code', result.department_code);
        body.set('role', result.role);
        body.set('phone', result.phone);
        body.set('signature_email', result.signature_email);
        result.gender && body.set('gender', result.gender);

        if (result.role === Role.LAWYER) {
          result.fees_file && body.set('fees_file', result.fees_file);
          result.yo_process_value &&
            body.set('yo_process_value', result.yo_process_value);
        }
      } else {
        body = {
          first_name: result.first_name,
          last_name: result.last_name,
          role: result.role,
          email: result.email,
          department_code: result.department_code,
          gender: result.gender,
          support_days:
            result.role === Role.MANAGER
              ? result.support_days || null
              : undefined,
          support_hours:
            result.role === Role.MANAGER
              ? result.support_hours || null
              : undefined,
          support_booking_url:
            result.role === Role.MANAGER
              ? result.support_booking_url || null
              : undefined,
          phone: result.phone,
        };
      }

      createUserRequest({
        endpoint: `/${createUrl}`,
        body,
        onSuccess: () => {
          success(t('user.create_success'));
          setModalIsOpen(false);
          goBack();
        },
        onError: () => {
          error(t('user.create_error'));
        },
      });
    }
  }, [createUrl, result]);

  const handleRoleChange = (role: Role) => {
    setRole(role);

    if (role === Role.LAWYER) {
      setCreateUrl('lawyers');
    } else if (role === Role.MANAGER) {
      setCreateUrl('managers');
    } else {
      setCreateUrl('users');
    }
  };

  return (
    <Container>
      <Modal opened={modalIsOpen} onClose={onClose}>
        <ConfirmForm
          title={t('user.add_confirm_title')}
          description={t('user.add_confirm_description')}
          confirmText={t('user.add_confirm')}
          handleSubmit={createUser}
          setModalIsOpen={setModalIsOpen}
          loading={loading}
        />
      </Modal>
      <Breadcrumbs />
      <Flex direction={{ xs: 'row' }} alignItems="center">
        <Text
          content={t('user.add_user')}
          fontStyle="heading2"
          weight="bold"
          marginRight={{ xs: 'space32' }}
        />
      </Flex>
      <Form id="userForm" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="role"
          rules={{ required: t('error.form.user_role').toString() }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('user.user_role')}
              placeholder={t('user.user_role')}
              options={roleOptions}
              error={error}
              {...field}
              onChange={(e) => {
                field.onChange(e);
                handleRoleChange(e);
              }}
              required
            />
          )}
        />
        {role === Role.LAWYER ? (
          <InputText
            {...register('signature_email', {
              required: t('error.form.required').toString(),
              pattern: {
                value: regexes.email,
                message: t('error.form.email'),
              },
            })}
            error={errors.signature_email}
            label={t('user.signature_email')}
            placeholder={t('user.email')}
            width="100%"
          />
        ) : (
          <Flex />
        )}

        <InputText
          {...register('email', {
            required: t('error.form.required').toString(),
            pattern: {
              value: regexes.email,
              message: t('error.form.email'),
            },
          })}
          error={errors.email}
          label={t('user.email')}
          placeholder={t('user.email')}
          width="100%"
          required
        />

        <Controller
          control={control}
          name="phone"
          rules={{
            required: t('error.form.required').toString(),
            validate: (value: string) => {
              if (value && !isValidPhoneNumber(value)) {
                return t('error.form.phone').toString();
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputTel
              label={t('user.phone')}
              placeholder={t('user.phone')}
              error={error}
              {...field}
              onChange={(value) => {
                setValue(`phone`, value);
                clearErrors('phone');
              }}
              required
            />
          )}
        />

        <InputText
          {...register('first_name', {
            required: t('error.form.required').toString(),
          })}
          error={errors.first_name}
          label={t('user.first_name')}
          placeholder={t('user.first_name')}
          width="100%"
          required
          uppercase
        />
        <InputText
          {...register('last_name', {
            required: t('error.form.required').toString(),
          })}
          label={t('user.last_name')}
          placeholder={t('user.last_name')}
          error={errors.last_name}
          width="100%"
          required
          uppercase
        />

        <Controller
          control={control}
          name="gender"
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('user.gender')}
              placeholder={t('user.gender')}
              options={genderOptions}
              error={error}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="department_code"
          rules={{ required: t('error.form.region').toString() }}
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('user.department')}
              placeholder={t('user.department')}
              options={departmentOptions}
              error={error}
              {...field}
              required
            />
          )}
        />

        {role === Role.LAWYER && (
          <>
            <Controller
              control={control}
              name="fees_file"
              rules={{ required: t('error.form.template_id').toString() }}
              render={({ field, fieldState: { error } }) => (
                <InputFile
                  type={'file'}
                  size={1}
                  accept={'application/pdf'}
                  label={t('user.template_id')}
                  error={error?.message}
                  required
                  ref={field.ref}
                  onChange={(files) => {
                    setValue('fees_file', files[0] ?? null);
                  }}
                  name={field.name}
                />
              )}
            />

            <InputText
              {...register('yo_process_value', {
                required: t('error.form.required').toString(),
              })}
              label={t('user.yo_process_value')}
              error={errors.yo_process_value}
              placeholder={t('user.yo_process_value')}
              width="100%"
              required
            />
          </>
        )}

        {role === Role.MANAGER && (
          <InputText
            {...register('support_days', {
              required: t('error.form.required').toString(),
            })}
            error={errors.support_days}
            label={t('user.support_days')}
            placeholder={t('user.support_days_placeholder')}
            width="100%"
            required
          />
        )}

        {role === Role.MANAGER && (
          <InputText
            {...register('support_hours', {
              required: t('error.form.required').toString(),
            })}
            error={errors.support_hours}
            label={t('user.support_hours')}
            placeholder={t('user.support_hours_placeholder')}
            width="100%"
            required
          />
        )}

        {role === Role.MANAGER && (
          <InputText
            {...register('support_booking_url')}
            error={errors.support_booking_url}
            label={t('user.support_booking_url')}
            placeholder={t('user.support_booking_url_placeholder')}
            width="100%"
            required
          />
        )}

        <FullWidth>
          <Flex>
            <Button
              onClick={goBack}
              content={t('back')}
              marginRight={{ sm: 'space24' }}
            />
            <Button
              content={t('user.add_user')}
              type="submit"
              $loading={loading}
              primary
            />
          </Flex>
        </FullWidth>
      </Form>
    </Container>
  );
};

export default UserAdd;
