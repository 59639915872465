import { FC, useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Column } from 'react-table';

// Data
// Components
import { Col, Flex, Row, Text } from 'components/Layout';
import Table from 'components/Table/Table';
import { Button, OpportunityStatusButton } from 'components/Buttons';
import InputSelect from 'components/FormTemplate/Fields/InputSelect';
import { TextEllipsis } from 'components/Text';
import { DateInput } from 'components/Forms';

// Utils
import { theme } from 'theme';
import { FileModal, Modal, ModalHeader, UploadModal } from 'components/Modal';
import { parseISO } from 'date-fns';
import styled from 'styled-components';
import {
  Consent,
  ConsentStatus,
  Letter,
  DocumentFull,
  LetterStatus,
  LetterType,
  ProcedureFull,
  ProcedureSpouseFull,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';

const Container = styled.div`
  tbody {
    overflow: inherit;
  }
`;

type SpouseLetter = {
  id: string;
  spouse: ProcedureSpouseFull;
  letter: Letter | null;
};

interface RegisteredLettersBlocProps {
  procedure: ProcedureFull;
  spouses: ProcedureSpouseFull[];
  consents: Consent[];
  refetchProcedure: () => void;
}

const RegisteredLettersBloc: FC<RegisteredLettersBlocProps> = ({
  procedure,
  spouses,
  consents,
  refetchProcedure,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [letterToResend, setLetterToResend] = useState<string | null>(null);
  const [selectedLetter, setSelectedLetter] = useState<Letter | null>(null);
  const [selectedReceipt, setSelectedReceipt] = useState<DocumentFull | null>(
    null,
  );
  const [letterToSend, setLetterToSend] = useState<{
    recipient_id: string;
    index: number;
    type: LetterType;
  } | null>(null);
  const [sendModal, toggleSendModal] = useState<boolean>(false);
  const [resendModal, toggleResendModal] = useState<boolean>(false);
  const inputTypeRef = useRef<{ select: { clearValue: () => void } }[]>([]);

  const { execute: sendLetter, state: sendLetterState } = useApi(
    `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters`,
    {
      method: 'POST',
      onSuccess: () => {
        toggleSendModal(false);
        setLetterToSend(null);
        getDivorceAgreementLetters();
      },
    },
  );

  const { execute: removeLetter, state: removeLetterState } = useApi(
    `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters`,
    {
      method: 'DELETE',
      onSuccess: () => {
        getDivorceAgreementLetters();
        toggleResendModal(false);
      },
    },
  );

  const sendLetterCb = useCallback(() => {
    if (letterToSend) {
      sendLetter({
        endpoint: `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters`,
        body: {
          type: letterToSend.type,
          recipient_id: letterToSend.recipient_id,
        },
      });
    }
  }, [letterToSend]);

  const removeLetterCb = useCallback(() => {
    if (letterToResend) {
      removeLetter({
        endpoint: `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters/${letterToResend}`,
      });
    }
  }, [letterToResend]);

  const { execute: getProcedureDocument } = useApi<DocumentFull>(
    `/procedures/${procedure.id}/documents`,
  );

  const {
    execute: getDivorceAgreementLetters,
    state: getDivorceAgreementLettersState,
  } = useApi<Letter[]>(
    `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters`,
  );

  const divorceAgreementLetters =
    getDivorceAgreementLettersState.data?.value ?? [];

  const spousesLetters = spouses.map((spouse) => {
    const letter = divorceAgreementLetters.find(
      (l) => l.recipient_id === spouse.spouse_id,
    );

    return { id: spouse.spouse_id, spouse, letter: letter ?? null };
  });

  const {
    execute: uploadDivorceAgreementLetterReceipt,
    state: uploadDivorceAgreementLetterReceiptState,
  } = useApi(
    `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters`,
    { method: 'POST' },
  );
  const uploadLoading = uploadDivorceAgreementLetterReceiptState.loading;

  const { execute: validateStep, state: validateStepState } = useApi(
    `/procedures/${procedure.id}/steps/AGREEMENT_SENT`,
    {
      method: 'PATCH',
      onSuccess: () => {
        refetchProcedure();
      },
    },
  );
  const validateStepLoading = validateStepState.loading;

  const validateStepCb = useCallback(() => {
    validateStep({ body: { status: ProcedureStepStatus.VALIDATED } });
  }, [procedure.id]);

  const canValidateStep =
    procedure.step === ProcedureStepType.AGREEMENT_SENT &&
    spousesLetters.every(
      (sl) => sl.letter?.received_at && sl.letter.receipt_id,
    );

  const { execute: updateLetter } = useApi(
    `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters`,
    {
      method: 'PATCH',
      onSuccess: () => {
        getDivorceAgreementLetters();
      },
    },
  );

  const updateLetterCb = useCallback(
    (dal_id: string, body: { sent_at?: Date; received_at?: Date }) => {
      updateLetter({
        endpoint: `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters/${dal_id}/letter`,
        body,
      });
    },
    [procedure],
  );

  const onConfirmUpload = useCallback(
    (blob: Blob) => {
      if (selectedLetter) {
        const body = new FormData();
        body.append('file', blob);

        uploadDivorceAgreementLetterReceipt({
          endpoint: `/procedures/${procedure.id}/divorce-agreements/${procedure.divorce_agreement_id}/letters/${selectedLetter.id}/receipt`,
          body,
          onSuccess: () => {
            getDivorceAgreementLetters();
            handleCloseModal();
          },
        });
      }
    },
    [selectedLetter],
  );

  const getTypeByStatus = (status?: LetterStatus) => {
    switch (status) {
      case LetterStatus.BOUNCED:
      case LetterStatus.FAILED:
      case LetterStatus.REFUSED:
      case LetterStatus.NEGLECTED:
        return 'danger';
      case undefined:
        return 'info';
      case LetterStatus.CREATED:
      case LetterStatus.SENT:
        return 'warning';
      default:
      case LetterStatus.RECEIVED:
        return 'success';
    }
  };

  const typeOptions = Object.values(LetterType).map((value) => ({
    value,
    label: t(`letters.types.${value}`),
  }));

  const typeOptionsNoLre = Object.values(LetterType)
    .filter((v) => v !== LetterType.LRE)
    .map((value) => ({
      value,
      label: t(`letters.types.${value}`),
    }));

  const handleOpenModal = (letter: Letter) => {
    setSelectedLetter(letter);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenReceipt = (receiptId?: string | null) => {
    if (receiptId) {
      getProcedureDocument({
        endpoint: `/procedures/${procedure.id}/documents/${receiptId}`,
        onSuccess: (value) => {
          setSelectedReceipt(value.value);
        },
      });
    }
  };

  const columns: Column<SpouseLetter>[] = [
    {
      Header: t('letters.columns.name'),
      id: 'spouse_name',
      accessor: 'spouse',
      width: 120,
      disableSortBy: true,
      Cell: ({ value }) => (
        <Flex alignItems="center" height="100%">
          <TextEllipsis
            fontStyle="body1"
            content={value.spouse.full_name}
            weight="medium"
          />
        </Flex>
      ),
    },
    {
      Header: t('letters.columns.status'),
      id: 'letter_status',
      accessor: 'spouse',
      width: 80,
      disableSortBy: true,
      Cell: ({ cell }) => {
        const letter = cell.row.original.letter;

        return (
          <Flex alignItems="center" height="100%">
            <OpportunityStatusButton
              type={getTypeByStatus(letter?.status)}
              text={t(`letters.statuses.${letter?.status}`, {
                defaultValue: t(`letters.statuses.TO_SEND`),
              })}
            />
          </Flex>
        );
      },
    },
    {
      Header: t('letters.columns.send_type'),
      id: 'letter_type',
      accessor: 'spouse',
      width: 120,
      disableSortBy: true,
      Cell: ({ cell, value }) => {
        const letter = cell.row.original.letter;
        const hasLetter = Boolean(letter);
        const spouseConsent = consents.some(
          (c) =>
            c.customer_id === value.spouse_id &&
            c.status === ConsentStatus.VALIDATED,
        );
        const cannotSendLre =
          !hasLetter && !procedure.notary_partner && !spouseConsent;
        const filteredOptions = cannotSendLre ? typeOptionsNoLre : typeOptions;

        return (
          <Flex>
            <InputSelect
              ref={(ref: any) => {
                if (inputTypeRef.current !== null) {
                  inputTypeRef.current[cell.row.index] = ref;
                }
              }}
              onChange={(type) => {
                if (type) {
                  setLetterToSend({
                    recipient_id: value.spouse_id,
                    index: cell.row.index,
                    type,
                  });
                  toggleSendModal(true);
                }
              }}
              options={filteredOptions}
              placeholder={t('registered_letters.send_type.placeholder')}
              size="small"
              value={letter?.type}
              disabled={hasLetter}
            />
          </Flex>
        );
      },
    },
    {
      Header: t('letters.columns.send_date'),
      id: 'letter_sent_at',
      accessor: 'letter',
      disableSortBy: true,
      width: 120,
      Cell: ({ value }) => {
        const letter = value;
        const disabled = !letter || letter.type === LetterType.LRE;

        return (
          <DateInput
            disableOpenPicker={disabled}
            inputStyle={{ padding: '1rem', textAlign: 'center' }}
            onAccept={(value) => {
              const isValid =
                value instanceof Date &&
                !isNaN(value.getTime()) &&
                value?.getFullYear() > 1900;

              if (isValid && letter) {
                updateLetterCb(letter.id, { sent_at: value });
              }
            }}
            onChange={(value) => {
              const isValid =
                value instanceof Date &&
                !isNaN(value.getTime()) &&
                value?.getFullYear() > 1900;

              if (isValid && letter) {
                updateLetterCb(letter.id, { sent_at: value });
              }
            }}
            value={letter?.sent_at ? parseISO(letter.sent_at) : null}
            disabled={disabled}
          />
        );
      },
    },
    {
      Header: t('letters.columns.received_date'),
      id: 'letter_received_at',
      accessor: 'letter',
      disableSortBy: true,
      width: 120,
      Cell: ({ value }) => {
        const letter = value;
        const disabled =
          !letter ||
          letter.type === LetterType.LRE ||
          (letter.type === LetterType.LRAR && !letter.sent_at);

        return (
          <DateInput
            disableOpenPicker={disabled}
            inputStyle={{ padding: '1rem', textAlign: 'center' }}
            onAccept={(value) => {
              const isValid =
                value instanceof Date &&
                !isNaN(value.getTime()) &&
                value?.getFullYear() > 1900;

              if (isValid && letter) {
                updateLetterCb(letter.id, { received_at: value });
              }
            }}
            onChange={(value) => {
              const isValid =
                value instanceof Date &&
                !isNaN(value.getTime()) &&
                value?.getFullYear() > 1900;

              if (isValid && letter) {
                updateLetterCb(letter.id, { received_at: value });
              }
            }}
            value={letter?.received_at ? parseISO(letter.received_at) : null}
            disabled={disabled}
          />
        );
      },
    },
    {
      Header: t('letters.columns.receipt'),
      id: 'letter_receipt_id',
      accessor: 'letter',
      disableSortBy: true,
      width: 130,
      Cell: ({ value }) => {
        const letter = value;

        const shouldUploadReceipt =
          letter?.type && letter.type === LetterType.LRAR && !letter.receipt_id;

        const content = shouldUploadReceipt
          ? t('letters.receipt.upload')
          : t('letters.receipt.view');

        const canUploadReceipt =
          shouldUploadReceipt &&
          letter.status &&
          letter.status === LetterStatus.RECEIVED;

        const canViewReceipt =
          letter?.status &&
          [
            LetterStatus.RECEIVED,
            LetterStatus.NEGLECTED,
            LetterStatus.REFUSED,
            LetterStatus.BOUNCED,
          ].includes(letter.status) &&
          letter.receipt_id;
        const enabled = canUploadReceipt || canViewReceipt;

        return (
          <Flex alignItems="center" height="100%">
            <Button
              size="small"
              onClick={() => {
                shouldUploadReceipt
                  ? handleOpenModal(letter)
                  : handleOpenReceipt(letter?.receipt_id);
              }}
              content={content}
              primary
              disabled={!enabled}
              marginRight={{ xs: letter?.receipt_id ? 'space8' : 'none' }}
            />
          </Flex>
        );
      },
    },
    {
      Header: t('letters.columns.resend'),
      id: 'letter_resend',
      accessor: 'letter',
      disableSortBy: true,
      width: 80,
      Cell: ({ value }) => {
        const letter = value;
        const status = letter?.status;
        const canResend = status;

        return (
          <Flex alignItems="center" height="100%">
            <Button
              size="small"
              onClick={() => {
                if (letter) {
                  setLetterToResend(letter.id);
                  toggleResendModal(true);
                }
              }}
              content={t('letters.send.resend')}
              backgroundColor={theme.colors.green1}
              textColor={theme.colors.white}
              disabled={!canResend}
              marginRight={{ xs: letter ? 'space8' : 'none' }}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <Container>
      <Text fontStyle={'heading2'} content={t('letters.title')} />

      <Row>
        <Col xs={12}>
          <Table<SpouseLetter>
            data={spousesLetters}
            columns={columns}
            pageSize={2}
            totalCount={divorceAgreementLetters?.length || 0}
            loadMore={() => getDivorceAgreementLetters()}
            noSeparator
            backgroundColor={theme.colors.beige}
            rounded
            asCard
            disableSortBy
          />
        </Col>
      </Row>
      <Flex marginTop={{ xs: 'space16' }} justify="end">
        <Button
          size="small"
          primary
          content={t('recommended.validate_step')}
          disabled={!canValidateStep}
          onClick={validateStepCb}
          $loading={validateStepLoading}
        />
      </Flex>

      {selectedLetter && isModalOpen && (
        <UploadModal
          opened={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={onConfirmUpload}
          loading={uploadLoading}
          tip={false}
          documentName="Accusé de réception"
        />
      )}

      {selectedReceipt && (
        <FileModal
          opened={Boolean(selectedReceipt)}
          onClose={() => setSelectedReceipt(null)}
          refetchDocuments={getDivorceAgreementLetters}
          hideTip
          changeable
          document={selectedReceipt}
          procedure={procedure}
        />
      )}

      {/* Send confirmation modal */}
      <Modal opened={sendModal} full onClose={() => toggleSendModal(false)}>
        <ModalHeader
          content={t('letters.send.title', { type: letterToSend?.type })}
          iconName="Danger"
        />
        <Text
          fontStyle="body1"
          marginTop={{ xs: 'space16' }}
          content={t('letters.send.description', { type: letterToSend?.type })}
        />
        <Flex marginTop={{ xs: 'space40' }}>
          <Button
            content={t('letters.send.confirm', { type: letterToSend?.type })}
            marginRight={{ xs: 'space16' }}
            onClick={() => sendLetterCb()}
            $loading={sendLetterState.loading}
          />
          <Button
            content={t('letters.send.cancel')}
            onClick={() => {
              if (letterToSend) {
                inputTypeRef.current[letterToSend.index].select.clearValue();
              }
              toggleSendModal(false);
            }}
            primary
          />
        </Flex>
      </Modal>

      {/* Resend confirmation modal */}
      <Modal opened={resendModal} full onClose={() => toggleResendModal(false)}>
        <ModalHeader
          content={t('letters.resend.title')}
          iconName="Message"
          onClose={() => toggleResendModal(false)}
        />
        <Text fontStyle="body1" marginTop={{ xs: 'space16' }}>
          <Trans i18nKey="letters.resend.description" />
        </Text>
        <Flex marginTop={{ xs: 'space40' }} justify={'end'}>
          <Button
            content={t('letters.resend.confirm')}
            marginRight={{ xs: 'space16' }}
            onClick={() => removeLetterCb()}
            $loading={removeLetterState.loading}
          />
          <Button
            content={t('letters.resend.cancel')}
            onClick={() => {
              toggleResendModal(false);
            }}
            primary
          />
        </Flex>
      </Modal>
    </Container>
  );
};

export default RegisteredLettersBloc;
